import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'
import { getAxiosObject } from '@/utils/library'
import { decodeBase64fromBuffer } from '@/utils/base64'
import { dataCsvToArray } from '@/utils/comma_string'
import { mutationUpdateAfter } from '@/utils/update_after'

const dataName = 'medicalContentImages'

const state = {
  medicalContentImages: [],
  updatedAt: '20101010101010',
  karteMedicalContentImages: {},
  resizedMedicalContentImages: {},
  resizedMedicalContentImagesByOriginalId: {}
}

const getters = {
  ...commonGetters(dataName, ['getData', 'getDataIncludeDel', 'getUpdatedAt'], {
    filterDel: true
  }),
  getKarteMedicalContentImages(state) {
    return state.karteMedicalContentImages
  },
  getKarteMedicalContentImage: state => medicalContentImageId => {
    return state.karteMedicalContentImages[medicalContentImageId]
  },
  getResizedMedicalContentImages(state) {
    return state.resizedMedicalContentImages
  },
  getResizedMedicalContentImage: state => medicalContentImageId => {
    return state.resizedMedicalContentImages[medicalContentImageId]
  },
  getResizedMedicalContentImageByOriginalId: state => medicalContentImageOriginalId => {
    return state.resizedMedicalContentImagesByOriginalId[
      medicalContentImageOriginalId
    ]
  }
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async update({ commit }, obj) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/medical-content-images', obj)
      if (res.status === 200) {
        commit('updateAfter', [res.data.medicalContentImage])
        commit('imageTags/updateAfter', res.data.imageTags, { root: true })
        return 'success'
      }
    } catch (err) {
      return err.response?.data?.message === 'no data'
        ? `no ${err.response.data.extra}`
        : 'server error'
    }
  },
  setKarteMedicalContentImages({ commit }, images) {
    commit('setKarteMedicalContentImages', images)
  },
  setResizedMedicalContentImage({ commit }, medicalContentImage) {
    commit('setResizedMedicalContentImage', medicalContentImage)
  },
  resetResizedMedicalContentImages({ commit }) {
    commit('resetResizedMedicalContentImages')
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt'], {
    csvColumns: ['imageTagsIds']
  }),
  updateAfter(state, images) {
    const convertImages = dataCsvToArray(images, ['imageTagsIds'])
    const decodedImages = decodeBase64fromBuffer(convertImages)
    mutationUpdateAfter(state, decodedImages, dataName)
    decodedImages.forEach(image => {
      state.karteMedicalContentImages[image.id] = image
    })
  },
  setKarteMedicalContentImages(state, images) {
    const decodedImages = decodeBase64fromBuffer(images)
    decodedImages.forEach(image => {
      state.karteMedicalContentImages[image.id] = image
    })
  },
  setResizedMedicalContentImage(state, resizedMedicalContentImage) {
    state.resizedMedicalContentImages[
      resizedMedicalContentImage.id
    ] = resizedMedicalContentImage
    state.resizedMedicalContentImagesByOriginalId[
      resizedMedicalContentImage.originalId
    ] = resizedMedicalContentImage
  },
  resetResizedMedicalContentImages(state) {
    state.resizedMedicalContentImages = {}
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
