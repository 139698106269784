import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store'
import { mixinHandleSignIn } from '@/components/mixins/ApiLogin'
import { SESSION_STORAGE_KEY } from '@/utils/define'

import Login from '@/components/pages/Login'
import ApplyForm from '@/components/pages/ApplyForm'
import Vrainers from '@/components/pages/Vrainers'
import VrainersImage from '@/components/pages/VrainersImage'
import VrainersSettings from '@/components/pages/VrainersSettings'
import ApplyRegistered from '@/components/pages/ApplyRegistered'
import CustomerLoginManagement from '@/components/pages/CustomerLoginManagement'
import PasswordResetSendMail from '@/components/pages/PasswordResetSendMail'
import PasswordResetSendAfter from '@/components/pages/PasswordResetSendAfter'
import PasswordReset from '@/components/pages/PasswordReset'
import PasswordResetRegistered from '@/components/pages/PasswordResetRegistered'
import MailChangeAuthorization from '@/components/pages/MailChangeAuthorization'
import MaintenanceMode from '@/components/pages/MaintenanceMode'
import NoticeNew from '@/components/pages/NoticeNew'
import Sample from '@/components/main_contents/Sample'

const Main = () => import(/*webpackChunkName:"main"*/ '@/components/pages/Main')
const MainContents = () =>
  import(/*webpackChunkName:"main"*/ '@/components/main_contents/MainContents')
const Owners = () =>
  import(
    /*webpackChunkName:"ownerPatients"*/ '@/components/main_contents/owner_patients/Owners'
  )
const OwnerNew = () =>
  import(
    /*webpackChunkName:"ownerPatients"*/ '@/components/main_contents/owner_patients/OwnerNew'
  )
const Owner = () =>
  import(
    /*webpackChunkName:"ownerPatients"*/ '@/components/main_contents/owner_patients/Owner'
  )
const OwnerEdit = () =>
  import(
    /*webpackChunkName:"ownerPatients"*/ '@/components/main_contents/owner_patients/OwnerEdit'
  )
const PatientNew = () =>
  import(
    /*webpackChunkName:"ownerPatients"*/ '@/components/main_contents/owner_patients/PatientNew'
  )
const PatientEdit = () =>
  import(
    /*webpackChunkName:"ownerPatients"*/ '@/components/main_contents/owner_patients/PatientEdit'
  )
const Hospitalizations = () =>
  import(
    /*webpackChunkName:"hospitalizations"*/ '@/components/main_contents/hospitalizations/Hospitalizations'
  )
const KarteSetting = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/KarteSetting'
  )
const MedicalRecordSetting = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/MedicalRecordSetting'
  )
const MedicalRecordsAll = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/MedicalRecordsAll'
  )
const MedicalContents = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/MedicalContents'
  )
const MedicalPayments = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/MedicalPayments'
  )
const KarteImageList = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/KarteImageList'
  )
const MedicalRecordNew = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/MedicalRecordNew'
  )
const PatientMedicalRecordImages = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/PatientMedicalRecordImages'
  )
const MedicalRecordEdit = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/MedicalRecordEdit'
  )
const Estimates = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/Estimates'
  )
const PatientMedicalContents = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/PatientMedicalContents'
  )
const PatientMedicalPayments = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/PatientMedicalPayments'
  )
const EstimateNew = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/EstimateNew'
  )
const EstimateEdit = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/EstimateEdit'
  )
const Examinations = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/Examinations'
  )
const PatientMeasurementResults = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/PatientMeasurementResults'
  )
const PatientPayments = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/PatientPayments'
  )
const PatientPaymentShow = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/PatientPaymentShow'
  )
const PatientPaymentNew = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/PatientPaymentNew'
  )
const PatientVaccinations = () =>
  import(
    /*webpackChunkName:"karte"*/ '@/components/main_contents/karte/PatientVaccinations'
  )
const VaccinationSets = () =>
  import(
    /*webpackChunkName:"vaccinations"*/ '@/components/main_contents/vaccinations/VaccinationSets'
  )
const Payments = () =>
  import(
    /*webpackChunkName:"payments"*/ '@/components/main_contents/payments/Payments'
  )
const AnalyticsSetting = () =>
  import(
    /*webpackChunkName:"analytics"*/ '@/components/main_contents/analytics/AnalyticsSetting'
  )
const PaymentAggregations = () =>
  import(
    /*webpackChunkName:"analytics"*/ '@/components/parts/organisms/PaymentAggregations'
  )
const LineGraph = () =>
  import(
    /*webpackChunkName:"analytics"*/ '@/components/parts/organisms/LineGraph'
  )
const ReceiptsSetting = () =>
  import(
    /*webpackChunkName:"receipts"*/ '@/components/main_contents/receipts/ReceiptsSetting'
  )
const AnicomReportShow = () =>
  import(
    /*webpackChunkName:"receipts"*/ '@/components/main_contents/receipts/AnicomReportShow'
  )
const Notices = () =>
  import(
    /*webpackChunkName:"notifications"*/ '@/components/main_contents/notifications/Notices'
  )
const NoticeShow = () =>
  import(
    /*webpackChunkName:"notifications"*/ '@/components/main_contents/notifications/NoticeShow'
  )
const NotificationsSetting = () =>
  import(
    /*webpackChunkName:"notifications"*/ '@/components/main_contents/notifications/NotificationsSetting'
  )
const ReceivedMails = () =>
  import(
    /*webpackChunkName:"notifications"*/ '@/components/main_contents/notifications/ReceivedMails'
  )
const ReceivedMailShow = () =>
  import(
    /*webpackChunkName:"notifications"*/ '@/components/main_contents/notifications/ReceivedMailShow'
  )
const SendMails = () =>
  import(
    /*webpackChunkName:"notifications"*/ '@/components/main_contents/notifications/SendMails'
  )
const SendMailShow = () =>
  import(
    /*webpackChunkName:"notifications"*/ '@/components/main_contents/notifications/SendMailShow'
  )
const ReservationRequests = () =>
  import(
    /*webpackChunkName:"notifications"*/ '@/components/main_contents/notifications/ReservationRequests'
  )
const ReservationRequestShow = () =>
  import(
    /*webpackChunkName:"notifications"*/ '@/components/main_contents/notifications/ReservationRequestShow'
  )
const ReservationCancels = () =>
  import(
    /*webpackChunkName:"notifications"*/ '@/components/main_contents/notifications/ReservationCancels'
  )
const ReservationCancelShow = () =>
  import(
    /*webpackChunkName:"notifications"*/ '@/components/main_contents/notifications/ReservationCancelShow'
  )
const Settings = () =>
  import(/*webpackChunkName:"settings"*/ '@/components/pages/Settings')
const ContactSetting = () =>
  import(
    /*webpackChunkName:"contact"*/ '@/components/settings_contents/contact/ContactSetting'
  )
const ContactUs = () =>
  import(
    /*webpackChunkName:"contact"*/ '@/components/settings_contents/contact/ContactUs'
  )
const SupportLogin = () =>
  import(
    /*webpackChunkName:"contact"*/ '@/components/settings_contents/contact/SupportLogin'
  )
const CommonSetting = () =>
  import(
    /*webpackChunkName:"common"*/ '@/components/settings_contents/common/CommonSetting'
  )
const CommonSettingBase = () =>
  import(
    /*webpackChunkName:"common"*/ '@/components/settings_contents/common/CommonSettingBase'
  )
const OpenDate = () =>
  import(
    /*webpackChunkName:"common"*/ '@/components/settings_contents/common/OpenDate'
  )
const ClinicInformation = () =>
  import(
    /*webpackChunkName:"common"*/ '@/components/settings_contents/common/ClinicInformation'
  )
const Species = () =>
  import(
    /*webpackChunkName:"common"*/ '@/components/settings_contents/common/Species'
  )
const SpeciesNew = () =>
  import(
    /*webpackChunkName:"common"*/ '@/components/settings_contents/common/SpeciesNew'
  )
const SpeciesShow = () =>
  import(
    /*webpackChunkName:"common"*/ '@/components/settings_contents/common/SpeciesShow'
  )
const SpeciesEdit = () =>
  import(
    /*webpackChunkName:"common"*/ '@/components/settings_contents/common/SpeciesEdit'
  )
const DataImport = () =>
  import(
    /*webpackChunkName:"dataImport"*/ '@/components/settings_contents/common/DataImport'
  )
const PrintSetting = () =>
  import(
    /*webpackChunkName:"common"*/ '@/components/settings_contents/common/PrintSetting'
  )
const ReservationSetting = () =>
  import(
    /*webpackChunkName:"reservation"*/ '@/components/settings_contents/reservation_setting/ReservationSetting'
  )
const ReservationSettingBase = () =>
  import(
    /*webpackChunkName:"reservation"*/ '@/components/settings_contents/reservation_setting/ReservationSettingBase'
  )
const ReservationPurposes = () =>
  import(
    /*webpackChunkName:"reservation"*/ '@/components/settings_contents/reservation_setting/ReservationPurposes'
  )
const ReservationPurposeNew = () =>
  import(
    /*webpackChunkName:"reservation"*/ '@/components/settings_contents/reservation_setting/ReservationPurposeNew'
  )
const ReservationPurposeShow = () =>
  import(
    /*webpackChunkName:"reservation"*/ '@/components/settings_contents/reservation_setting/ReservationPurposeShow'
  )
const ReservationPurposeEdit = () =>
  import(
    /*webpackChunkName:"reservation"*/ '@/components/settings_contents/reservation_setting/ReservationPurposeEdit'
  )
const ReservationAvailablePatterns = () =>
  import(
    /*webpackChunkName:"reservation"*/ '@/components/settings_contents/reservation_setting/ReservationAvailablePatterns'
  )
const ReservationAvailablePatternNew = () =>
  import(
    /*webpackChunkName:"reservation"*/ '@/components/settings_contents/reservation_setting/ReservationAvailablePatternNew'
  )
const ReservationAvailablePatternShow = () =>
  import(
    /*webpackChunkName:"reservation"*/ '@/components/settings_contents/reservation_setting/ReservationAvailablePatternShow'
  )
const ReservationAvailablePatternEdit = () =>
  import(
    /*webpackChunkName:"reservation"*/ '@/components/settings_contents/reservation_setting/ReservationAvailablePatternEdit'
  )
const ReservationColumnSetting = () =>
  import(
    /*webpackChunkName:"column"*/ '@/components/settings_contents/reservation_column/ReservationColumnSetting'
  )
const ReservationColumnsTab = () =>
  import(
    /*webpackChunkName:"column"*/ '@/components/settings_contents/reservation_column/ReservationColumnsTab.vue'
  )
const ReservationColumnNew = () =>
  import(
    /*webpackChunkName:"column"*/ '@/components/settings_contents/reservation_column/ReservationColumnNew'
  )
const ReservationColumnShow = () =>
  import(
    /*webpackChunkName:"column"*/ '@/components/settings_contents/reservation_column/ReservationColumnShow'
  )
const ReservationColumnEdit = () =>
  import(
    /*webpackChunkName:"column"*/ '@/components/settings_contents/reservation_column/ReservationColumnEdit'
  )
const ReservationColumnGroupsTab = () =>
  import(
    /*webpackChunkName:"column"*/ '@/components/settings_contents/reservation_column/ReservationColumnGroupsTab'
  )
const ReservationColumnGroupNew = () =>
  import(
    /*webpackChunkName:"column"*/ '@/components/settings_contents/reservation_column/ReservationColumnGroupNew'
  )
const ReservationColumnGroupShow = () =>
  import(
    /*webpackChunkName:"column"*/ '@/components/settings_contents/reservation_column/ReservationColumnGroupShow'
  )
const ReservationColumnGroupEdit = () =>
  import(
    /*webpackChunkName:"column"*/ '@/components/settings_contents/reservation_column/ReservationColumnGroupEdit'
  )
const StaffsSetting = () =>
  import(
    /*webpackChunkName:"staffs"*/ '@/components/settings_contents/staffs/StaffsSetting'
  )
const Staffs = () =>
  import(
    /*webpackChunkName:"staffs"*/ '@/components/settings_contents/staffs/Staffs'
  )
const StaffNew = () =>
  import(
    /*webpackChunkName:"staffs"*/ '@/components/settings_contents/staffs/StaffNew'
  )
const StaffShow = () =>
  import(
    /*webpackChunkName:"staffs"*/ '@/components/settings_contents/staffs/StaffShow'
  )
const StaffEdit = () =>
  import(
    /*webpackChunkName:"staffs"*/ '@/components/settings_contents/staffs/StaffEdit'
  )
const ShiftsSetting = () =>
  import(
    /*webpackChunkName:"shifts"*/ '@/components/settings_contents/shifts/ShiftsSetting'
  )
const StaffWorkingDates = () =>
  import(
    /*webpackChunkName:"shifts"*/ '@/components/settings_contents/shifts/StaffWorkingDates'
  )
const Shifts = () =>
  import(
    /*webpackChunkName:"shifts"*/ '@/components/settings_contents/shifts/Shifts'
  )
const ShiftNew = () =>
  import(
    /*webpackChunkName:"shifts"*/ '@/components/settings_contents/shifts/ShiftNew'
  )
const ShiftShow = () =>
  import(
    /*webpackChunkName:"shifts"*/ '@/components/settings_contents/shifts/ShiftShow'
  )
const ShiftEdit = () =>
  import(
    /*webpackChunkName:"shifts"*/ '@/components/settings_contents/shifts/ShiftEdit'
  )
const ShiftPatterns = () =>
  import(
    /*webpackChunkName:"shifts"*/ '@/components/settings_contents/shifts/ShiftPatterns'
  )
const ShiftPatternNew = () =>
  import(
    /*webpackChunkName:"shifts"*/ '@/components/settings_contents/shifts/ShiftPatternNew'
  )
const ShiftPatternShow = () =>
  import(
    /*webpackChunkName:"shifts"*/ '@/components/settings_contents/shifts/ShiftPatternShow'
  )
const ShiftPatternEdit = () =>
  import(
    /*webpackChunkName:"shifts"*/ '@/components/settings_contents/shifts/ShiftPatternEdit'
  )
const WaitingStatuses = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/waiting_statuses/WaitingStatuses'
  )
const Cares = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/cares/Cares'
  )
const CareNew = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/cares/CareNew'
  )
const CareShow = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/cares/CareShow'
  )
const CareEdit = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/cares/CareEdit'
  )
const DeliverySetting = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/delivery/DeliverySetting'
  )
const PreviousMail = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/delivery/PreviousMail'
  )
const RequestMail = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/delivery/RequestMail'
  )
const ReservationMail = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/delivery/ReservationMail'
  )
const CancelMail = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/delivery/CancelMail'
  )
const ChangeMail = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/delivery/ChangeMail'
  )
const MailSetting = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/delivery/MailSetting'
  )
const SendMailTemplates = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/delivery/SendMailTemplates'
  )
const SendMailTemplateShow = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/delivery/SendMailTemplateShow'
  )
const SendMailTemplateNew = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/delivery/SendMailTemplateNew'
  )
const SendMailTemplateEdit = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/delivery/SendMailTemplateEdit'
  )
const Display = () =>
  import(
    /*webpackChunkName:"display"*/ '@/components/settings_contents/display/Display'
  )
const Diseases = () =>
  import(
    /*webpackChunkName:"diseases"*/ '@/components/settings_contents/diseases/Diseases'
  )
const DiseaseNew = () =>
  import(
    /*webpackChunkName:"diseases"*/ '@/components/settings_contents/diseases/DiseaseNew'
  )
const DiseaseEdit = () =>
  import(
    /*webpackChunkName:"diseases"*/ '@/components/settings_contents/diseases/DiseaseEdit'
  )
const DiseaseShow = () =>
  import(
    /*webpackChunkName:"diseases"*/ '@/components/settings_contents/diseases/DiseaseShow'
  )
const Treatments = () =>
  import(
    /*webpackChunkName:"treatments"*/ '@/components/settings_contents/treatments/Treatments'
  )
const TreatmentsSetting = () =>
  import(
    /*webpackChunkName:"treatments"*/ '@/components/settings_contents/treatments/TreatmentsSetting'
  )
const TreatmentShow = () =>
  import(
    /*webpackChunkName:"treatments"*/ '@/components/settings_contents/treatments/TreatmentShow'
  )
const TreatmentNew = () =>
  import(
    /*webpackChunkName:"treatments"*/ '@/components/settings_contents/treatments/TreatmentNew'
  )
const TreatmentClassEdit = () =>
  import(
    /*webpackChunkName:"treatments"*/ '@/components/settings_contents/treatments/TreatmentClassEdit'
  )
const TreatmentEdit = () =>
  import(
    /*webpackChunkName:"treatments"*/ '@/components/settings_contents/treatments/TreatmentEdit'
  )
const TreatmentSets = () =>
  import(
    /*webpackChunkName:"treatments"*/ '@/components/settings_contents/treatments/TreatmentSets'
  )
const TreatmentSetShow = () =>
  import(
    /*webpackChunkName:"treatments"*/ '@/components/settings_contents/treatments/TreatmentSetShow'
  )
const TreatmentSetNew = () =>
  import(
    /*webpackChunkName:"treatments"*/ '@/components/settings_contents/treatments/TreatmentSetNew'
  )
const TreatmentSetEdit = () =>
  import(
    /*webpackChunkName:"treatments"*/ '@/components/settings_contents/treatments/TreatmentSetEdit'
  )
const ExaminationNew = () =>
  import(
    /*webpackChunkName:"treatments"*/ '@/components/settings_contents/treatments/examinations/ExaminationNew'
  )
const ExaminationShow = () =>
  import(
    /*webpackChunkName:"treatments"*/ '@/components/settings_contents/treatments/examinations/ExaminationShow'
  )
const ExaminationEdit = () =>
  import(
    /*webpackChunkName:"treatments"*/ '@/components/settings_contents/treatments/examinations/ExaminationEdit'
  )
const Medicines = () =>
  import(
    /*webpackChunkName:"medicines"*/ '@/components/settings_contents/medicines/Medicines'
  )
const MedicineNew = () =>
  import(
    /*webpackChunkName:"medicines"*/ '@/components/settings_contents/medicines/MedicineNew'
  )
const MedicineShow = () =>
  import(
    /*webpackChunkName:"medicines"*/ '@/components/settings_contents/medicines/MedicineShow'
  )
const MedicineEdit = () =>
  import(
    /*webpackChunkName:"medicines"*/ '@/components/settings_contents/medicines/MedicineEdit'
  )
const MedicineClassEdit = () =>
  import(
    /*webpackChunkName:"medicines"*/ '@/components/settings_contents/medicines/MedicineClassEdit'
  )
const TemplateSetting = () =>
  import(
    /*webpackChunkName:"settings"*/ '@/components/settings_contents/template/TemplateSetting'
  )
const MedicalContentTemplates = () =>
  import(
    /*webpackChunkName:"template"*/ '@/components/settings_contents/template/MedicalContentTemplates'
  )
const MedicalContentTemplateShow = () =>
  import(
    /*webpackChunkName:"template"*/ '@/components/settings_contents/template/MedicalContentTemplateShow'
  )
const MedicalContentTemplateEdit = () =>
  import(
    /*webpackChunkName:"template"*/ '@/components/settings_contents/template/MedicalContentTemplateEdit'
  )
const MedicalContentTemplateNew = () =>
  import(
    /*webpackChunkName:"template"*/ '@/components/settings_contents/template/MedicalContentTemplateNew'
  )
const EstimateTemplates = () =>
  import(
    /*webpackChunkName:"template"*/ '@/components/settings_contents/template/EstimateTemplates'
  )
const EstimateTemplateNew = () =>
  import(
    /*webpackChunkName:"template"*/ '@/components/settings_contents/template/EstimateTemplateNew'
  )
const EstimateTemplateShow = () =>
  import(
    /*webpackChunkName:"template"*/ '@/components/settings_contents/template/EstimateTemplateShow'
  )
const EstimateTemplateEdit = () =>
  import(
    /*webpackChunkName:"template"*/ '@/components/settings_contents/template/EstimateTemplateEdit'
  )

Vue.use(Router)

export const routes = [
  {
    path: '/settings',
    component: Settings,
    name: 'settings',
    children: [
      {
        path: 'contact',
        component: ContactSetting,
        children: [
          {
            path: 'contact-us',
            name: 'contact-us',
            component: ContactUs
          },
          {
            path: 'support-login',
            name: 'support-login',
            component: SupportLogin
          }
        ]
      },
      {
        path: 'common',
        component: CommonSetting,
        children: [
          {
            path: 'base',
            name: 'common-base',
            component: CommonSettingBase
          },
          {
            path: 'open-date',
            name: 'open-date',
            component: OpenDate
          },
          {
            path: 'clinic-information',
            name: 'clinic-information',
            component: ClinicInformation
          },
          {
            path: 'species',
            name: 'species',
            component: Species
          },
          {
            path: 'species/new',
            name: 'species-new',
            component: SpeciesNew
          },
          {
            path: 'species/:id',
            name: 'species-show',
            component: SpeciesShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'species/:id/edit',
            name: 'species-edit',
            component: SpeciesEdit,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'data-import',
            name: 'data-import',
            component: DataImport,
            meta: { requireAdmin: true }
          },
          {
            path: 'print-setting',
            name: 'print-setting',
            component: PrintSetting
          }
        ]
      },
      {
        path: 'reservation-setting',
        component: ReservationSetting,
        children: [
          {
            path: 'base',
            name: 'reservation-setting-base',
            component: ReservationSettingBase
          },
          {
            path: 'reservation-purposes',
            name: 'reservation-purposes',
            component: ReservationPurposes
          },
          {
            path: 'reservation-purposes/new',
            name: 'reservation-purpose-new',
            component: ReservationPurposeNew
          },
          {
            path: 'reservation-purposes/:id',
            name: 'reservation-purpose-show',
            component: ReservationPurposeShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'reservation-purposes/:id/edit',
            name: 'reservation-purpose-edit',
            component: ReservationPurposeEdit,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'reservation-available-patterns',
            name: 'reservation-available-patterns',
            component: ReservationAvailablePatterns
          },
          {
            path: 'reservation-available-patterns/new',
            name: 'reservation-available-pattern-new',
            component: ReservationAvailablePatternNew
          },
          {
            path: 'reservation-available-patterns/:id',
            name: 'reservation-available-pattern-show',
            component: ReservationAvailablePatternShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'reservation-available-patterns/:id/edit',
            name: 'reservation-available-pattern-edit',
            component: ReservationAvailablePatternEdit,
            props: route => ({ id: Number(route.params.id) })
          }
        ]
      },
      {
        path: 'reservation-column',
        component: ReservationColumnSetting,
        children: [
          {
            path: 'reservation-columns',
            name: 'reservation-columns-tab',
            component: ReservationColumnsTab
          },
          {
            path: 'reservation-columns/new',
            name: 'reservation-column-new',
            component: ReservationColumnNew
          },
          {
            path: 'reservation-columns/:id',
            name: 'reservation-column-show',
            component: ReservationColumnShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'reservation-columns/:id/edit',
            name: 'reservation-column-edit',
            component: ReservationColumnEdit,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'reservation-column-groups',
            name: 'reservation-column-groups-tab',
            component: ReservationColumnGroupsTab
          },
          {
            path: 'reservation-column-groups/new',
            name: 'reservation-column-group-new',
            component: ReservationColumnGroupNew
          },
          {
            path: 'reservation-column-groups/:id',
            name: 'reservation-column-group-show',
            component: ReservationColumnGroupShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'reservation-column-groups/:id/edit',
            name: 'reservation-column-group-edit',
            component: ReservationColumnGroupEdit,
            props: route => ({ id: Number(route.params.id) })
          }
        ]
      },
      {
        path: 'staffs',
        component: StaffsSetting,
        children: [
          {
            path: '',
            name: 'staffs',
            component: Staffs
          },
          {
            path: 'new',
            component: StaffNew
          },
          {
            path: ':id',
            name: 'staff-show',
            component: StaffShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: ':id/edit',
            name: 'staff-edit',
            component: StaffEdit,
            props: route => ({ id: Number(route.params.id) })
          }
        ]
      },
      {
        path: 'shifts',
        component: ShiftsSetting,
        children: [
          {
            path: 'staff-working-dates',
            name: 'staff-working-dates',
            component: StaffWorkingDates
          },
          {
            path: 'shifts',
            name: 'shifts',
            component: Shifts
          },
          {
            path: 'shifts/new',
            name: 'shift-new',
            component: ShiftNew
          },
          {
            path: 'shifts/:id',
            name: 'shift-show',
            component: ShiftShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'shifts/:id/edit',
            name: 'shift-edit',
            component: ShiftEdit,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'shift-patterns',
            name: 'shift-patterns',
            component: ShiftPatterns
          },
          {
            path: 'shift-patterns/new',
            name: 'shift-pattern-new',
            component: ShiftPatternNew
          },
          {
            path: 'shift-patterns/:id',
            name: 'shift-pattern-show',
            component: ShiftPatternShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'shift-patterns/:id/edit',
            name: 'shift-pattern-edit',
            component: ShiftPatternEdit,
            props: route => ({ id: Number(route.params.id) })
          }
        ]
      },
      {
        path: 'waiting-statuses',
        name: 'waiting-statuses',
        component: WaitingStatuses
      },
      {
        path: 'cares',
        name: 'cares',
        component: Cares
      },
      {
        path: 'cares/new',
        name: 'care-new',
        component: CareNew
      },
      {
        path: 'cares/:id',
        name: 'care-show',
        component: CareShow,
        props: route => ({ id: Number(route.params.id) })
      },
      {
        path: 'cares/:id/edit',
        name: 'care-edit',
        component: CareEdit,
        props: route => ({ id: Number(route.params.id) })
      },
      {
        path: 'delivery',
        component: DeliverySetting,
        children: [
          {
            path: 'previous-mail',
            name: 'previous-mail',
            component: PreviousMail
          },
          {
            path: 'request-mail',
            name: 'request-mail',
            component: RequestMail
          },
          {
            path: 'reservation-mail',
            name: 'reservation-mail',
            component: ReservationMail
          },
          {
            path: 'cancel-mail',
            name: 'cancel-mail',
            component: CancelMail
          },
          {
            path: 'change-mail',
            name: 'change-mail',
            component: ChangeMail
          },
          {
            path: 'mail-setting',
            name: 'mail-setting',
            component: MailSetting
          },
          {
            path: 'send-mail-templates',
            name: 'send-mail-templates',
            component: SendMailTemplates
          },
          {
            path: 'send-mail-templates/new',
            name: 'send-mail-template-new',
            component: SendMailTemplateNew
          },
          {
            path: 'send-mail-templates/:id',
            name: 'send-mail-template-show',
            component: SendMailTemplateShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'send-mail-templates/:id/edit',
            name: 'send-mail-template-edit',
            component: SendMailTemplateEdit,
            props: route => ({ id: Number(route.params.id) })
          }
        ]
      },
      { path: 'display', component: Display, name: 'display' },
      { path: 'diseases', component: Diseases, name: 'diseases' },
      {
        path: 'diseases/new',
        component: DiseaseNew,
        name: 'disease-new',
        props: route => ({ id: Number(route.params.id) })
      },
      {
        path: 'diseases/:target/:originalId/edit',
        component: DiseaseEdit,
        name: 'diseases-edit',
        props: route => ({
          originalId: Number(route.params.originalId),
          target: route.params.target
        })
      },
      {
        path: 'diseases/diseases/:originalId',
        component: DiseaseShow,
        name: 'disease-show',
        props: route => ({ originalId: Number(route.params.originalId) })
      },
      {
        path: 'treatments',
        component: TreatmentsSetting,
        children: [
          {
            path: 'treatments',
            name: 'treatments',
            component: Treatments
          },
          {
            path: 'treatments/new',
            name: 'treatment-new',
            component: TreatmentNew,
            props: route => ({ from: route.params.from, id: route.params.id })
          },
          {
            path: 'treatments/:originalId',
            name: 'treatment-show',
            component: TreatmentShow,
            props: route => ({ originalId: Number(route.params.originalId) })
          },
          {
            path: 'treatments/:originalId/edit',
            name: 'treatment-edit',
            component: TreatmentEdit,
            props: route => ({ originalId: Number(route.params.originalId) })
          },
          {
            path: 'treatments/:originalId/examinations/new',
            name: 'examination-new',
            component: ExaminationNew,
            props: route => ({
              originalId: Number(route.params.originalId)
            })
          },
          {
            path: 'treatments/:originalId/examinations/show',
            name: 'examination-show',
            component: ExaminationShow,
            props: route => ({
              originalId: Number(route.params.originalId)
            })
          },
          {
            path: 'treatments/:originalId/examinations/edit',
            name: 'examination-edit',
            component: ExaminationEdit,
            props: route => ({
              originalId: Number(route.params.originalId)
            })
          },
          {
            path: 'treatment-sets',
            name: 'treatment-sets',
            component: TreatmentSets
          },
          {
            path: 'treatment-sets/new',
            name: 'treatment-set-new',
            component: TreatmentSetNew
          },
          {
            path: 'treatment-sets/:id',
            name: 'treatment-set-show',
            component: TreatmentSetShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'treatment-sets/:id/edit',
            name: 'treatment-set-edit',
            component: TreatmentSetEdit,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: ':target/:originalId/edit',
            name: 'treatment-class-edit',
            component: TreatmentClassEdit,
            props: route => ({
              originalId: Number(route.params.originalId),
              target: route.params.target
            })
          }
        ]
      },
      {
        path: 'medicines',
        name: 'medicines',
        component: Medicines
      },
      {
        path: 'medicines/new',
        name: 'medicine-new',
        component: MedicineNew,
        props: route => ({ from: route.params.from, id: route.params.id })
      },
      {
        path: 'medicines/medicines/:originalId',
        name: 'medicine-show',
        component: MedicineShow,
        props: route => ({ originalId: Number(route.params.originalId) })
      },
      {
        path: 'medicines/medicines/:originalId/edit',
        name: 'medicine-edit',
        component: MedicineEdit,
        props: route => ({ originalId: Number(route.params.originalId) })
      },
      {
        path: 'medicines/:target/:originalId/edit',
        name: 'medicine-class-edit',
        component: MedicineClassEdit,
        props: route => ({
          originalId: Number(route.params.originalId),
          target: route.params.target
        })
      },
      {
        path: 'template',
        component: TemplateSetting,
        children: [
          {
            path: 'medical-content-templates',
            name: 'medical-content-templates',
            component: MedicalContentTemplates
          },
          {
            path: 'medical-content-templates/new',
            name: 'medical-content-template-new',
            component: MedicalContentTemplateNew
          },
          {
            path: 'medical-content-templates/:id',
            name: 'medical-content-template-show',
            component: MedicalContentTemplateShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'medical-content-templates/:id/edit',
            name: 'medical-content-template-edit',
            component: MedicalContentTemplateEdit,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'estimate-templates',
            name: 'estimate-templates',
            component: EstimateTemplates
          },
          {
            path: 'estimate-templates/new',
            name: 'estimate-template-new',
            component: EstimateTemplateNew
          },
          {
            path: 'estimate-templates/:id',
            name: 'estimate-template-show',
            component: EstimateTemplateShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'estimate-templates/:id/edit',
            name: 'estimate-template-edit',
            component: EstimateTemplateEdit,
            props: route => ({ id: Number(route.params.id) })
          }
        ]
      }
    ]
  },
  { path: '/', redirect: '/login' },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { isPublic: true }
  },
  {
    path: '/apply-form',
    name: 'apply-form',
    component: ApplyForm,
    meta: { isPublic: true }
  },
  {
    path: '/vrainers',
    name: 'vrainers',
    component: Vrainers,
    meta: { isPublic: true }
  },
  {
    path: '/vrainers-image',
    name: 'vrainers-image',
    component: VrainersImage,
    meta: { isPublic: true }
  },
  {
    path: '/vrainers-settings',
    name: 'vrainers-settings',
    component: VrainersSettings,
    meta: { isPublic: true }
  },
  {
    path: '/apply-registered',
    name: 'apply-registered',
    component: ApplyRegistered,
    meta: { isPublic: true }
  },
  {
    path: '/customer-login-management',
    name: 'customer-login-management',
    component: CustomerLoginManagement,
    meta: { isPublic: true }
  },
  {
    path: '/notice-new',
    name: 'notice-new',
    component: NoticeNew,
    meta: { isPublic: true }
  },
  {
    path: '/password-reset-send-mail',
    name: 'password-reset-send-mail',
    component: PasswordResetSendMail,
    meta: { isPublic: true }
  },
  {
    path: '/password-reset-send-after',
    name: 'password-reset-send-after',
    component: PasswordResetSendAfter,
    meta: { isPublic: true }
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: PasswordReset,
    meta: { isPublic: true }
  },
  {
    path: '/password-reset-registered',
    name: 'password-reset-registered',
    component: PasswordResetRegistered,
    meta: { isPublic: true }
  },
  {
    path: '/mail-change-authorization',
    name: 'mail-change-authorization',
    component: MailChangeAuthorization,
    meta: { isPublic: true }
  },
  {
    path: '/maintenance',
    component: MaintenanceMode,
    name: 'maintenance-mode',
    meta: { isPublic: true }
  },
  {
    path: '/main',
    component: Main,
    children: [
      {
        path: '/main',
        name: 'main',
        component: MainContents
      },
      {
        path: 'owners',
        name: 'owners',
        component: Owners
      },
      {
        path: 'owners/new',
        name: 'owner-new',
        component: OwnerNew
      },
      {
        path: 'owners/:id',
        name: 'owner',
        component: Owner,
        props: route => ({ id: Number(route.params.id) })
      },
      {
        path: 'owners/:id/edit',
        component: OwnerEdit,
        name: 'owner-edit',
        props: route => ({ id: Number(route.params.id) })
      },
      {
        path: 'owners/:ownerId/patients/new',
        name: 'patient-new',
        component: PatientNew,
        props: route => ({ ownerId: Number(route.params.ownerId) })
      },
      {
        path: 'owners/:ownerId/patients/:id/edit',
        component: PatientEdit,
        name: 'patient-edit',
        props: route => ({
          ownerId: Number(route.params.ownerId),
          id: Number(route.params.id)
        })
      },
      {
        path: 'hospitalizations',
        name: 'hospitalizations',
        component: Hospitalizations
      },
      {
        path: 'karte/medical-records',
        component: MedicalRecordSetting,
        children: [
          {
            path: '/',
            name: 'medical-records-all',
            component: MedicalRecordsAll
          },
          {
            path: 'contents',
            name: 'medical-contents',
            component: MedicalContents
          },
          {
            path: 'payments',
            name: 'medical-payments',
            component: MedicalPayments
          },
          {
            path: 'images',
            name: 'karte-image-list',
            component: KarteImageList
          }
        ]
      },
      {
        path: 'karte/owners/:ownerId/patients/:patientId',
        name: 'karte-setting',
        component: KarteSetting,
        props: route => ({
          ownerId: Number(route.params.ownerId),
          patientId: Number(route.params.patientId)
        }),
        children: [
          {
            path: 'medical-records/new',
            name: 'medical-record-new',
            meta: {
              karteLock: true,
              newKarteFlg: true,
              paymentFlg: false,
              kartePageFlg: true
            },
            component: MedicalRecordNew,
            props: route => ({
              ownerId: Number(route.params.ownerId),
              patientId: Number(route.params.patientId)
            })
          },
          {
            path: 'medical-records/:originalId/edit',
            name: 'medical-record-edit',
            meta: {
              karteLock: true,
              newKarteFlg: false,
              paymentFlg: false,
              kartePageFlg: true
            },
            component: MedicalRecordEdit,
            props: route => ({
              ownerId: Number(route.params.ownerId),
              patientId: Number(route.params.patientId),
              originalId: Number(route.params.originalId)
            })
          },
          {
            path: 'medical-contents',
            name: 'patient-medical-contents',
            component: PatientMedicalContents,
            meta: { kartePageFlg: true },
            props: route => ({
              ownerId: Number(route.params.ownerId),
              patientId: Number(route.params.patientId)
            })
          },
          {
            path: 'medical-payments',
            name: 'patient-medical-payments',
            component: PatientMedicalPayments,
            meta: { kartePageFlg: true },
            props: route => ({
              ownerId: Number(route.params.ownerId),
              patientId: Number(route.params.patientId)
            })
          },
          {
            path: 'vaccinations',
            name: 'patient-vaccinations',
            component: PatientVaccinations,
            meta: { kartePageFlg: true },
            props: route => ({
              ownerId: Number(route.params.ownerId),
              patientId: Number(route.params.patientId)
            })
          },
          {
            path: 'examinations',
            name: 'medical-record-examinations',
            component: Examinations,
            meta: { kartePageFlg: true },
            props: route => ({
              ownerId: Number(route.params.ownerId),
              patientId: Number(route.params.patientId)
            })
          },
          {
            path: 'measurement-results',
            name: 'patient-measurement-results',
            component: PatientMeasurementResults,
            meta: { kartePageFlg: true },
            props: route => ({
              ownerId: Number(route.params.ownerId),
              patientId: Number(route.params.patientId)
            })
          },
          {
            path: 'images',
            name: 'patient-medical-record-images',
            component: PatientMedicalRecordImages,
            meta: { kartePageFlg: true },
            props: route => ({
              ownerId: Number(route.params.ownerId),
              patientId: Number(route.params.patientId)
            })
          },
          {
            path: 'estimates',
            name: 'estimates',
            component: Estimates,
            meta: { kartePageFlg: true },
            props: route => ({
              ownerId: Number(route.params.ownerId),
              patientId: Number(route.params.patientId)
            })
          },
          {
            path: 'estimates/new',
            name: 'estimate-new',
            component: EstimateNew,
            meta: { kartePageFlg: true },
            props: route => ({
              ownerId: Number(route.params.ownerId),
              patientId: Number(route.params.patientId)
            })
          },
          {
            path: 'estimates/:id/edit',
            name: 'estimate-edit',
            component: EstimateEdit,
            meta: { kartePageFlg: true },
            props: route => ({
              id: Number(route.params.id),
              ownerId: Number(route.params.ownerId),
              patientId: Number(route.params.patientId)
            })
          },
          {
            path: 'payments',
            name: 'patient-payments',
            component: PatientPayments,
            meta: { kartePageFlg: true },
            props: route => ({
              ownerId: Number(route.params.ownerId),
              patientId: Number(route.params.patientId)
            })
          },
          {
            path: 'payments/:medicalPaymentOriginalId/show',
            name: 'patient-payment-show',
            component: PatientPaymentShow,
            meta: { kartePageFlg: true },
            props: route => ({
              ownerId: Number(route.params.ownerId),
              patientId: Number(route.params.patientId),
              medicalPaymentOriginalId: Number(
                route.params.medicalPaymentOriginalId
              )
            })
          },
          {
            path: 'payments/:medicalPaymentOriginalId/new',
            name: 'patient-payment-new',
            meta: { karteLock: true, paymentFlg: true, kartePageFlg: true },
            component: PatientPaymentNew,
            props: route => ({
              ownerId: Number(route.params.ownerId),
              patientId: Number(route.params.patientId),
              medicalPaymentOriginalId: Number(
                route.params.medicalPaymentOriginalId
              )
            })
          }
        ]
      },
      {
        path: 'vaccinations',
        name: 'vaccinations',
        component: VaccinationSets
      },
      {
        path: 'payments',
        name: 'payments',
        component: Payments
      },
      {
        path: 'analytics',
        name: 'analytics',
        component: AnalyticsSetting,
        children: [
          {
            path: 'payment-aggregations',
            name: 'payment-aggregations',
            component: PaymentAggregations
          },
          {
            path: 'line-graph',
            name: 'line-graph',
            component: LineGraph
          }
        ]
      },
      {
        path: 'anicom-reports',
        name: 'anicom-reports',
        component: ReceiptsSetting
      },
      {
        path: 'anicom-reports/:medicalPaymentId',
        name: 'anicom-report-show',
        component: AnicomReportShow,
        props: route => ({
          medicalPaymentId: Number(route.params.medicalPaymentId)
        })
      },
      {
        path: 'notifications',
        component: NotificationsSetting,
        children: [
          {
            path: 'notices',
            name: 'notices',
            component: Notices
          },
          {
            path: 'notices/:id',
            name: 'notice-show',
            component: NoticeShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'received-mails',
            name: 'received-mails',
            component: ReceivedMails
          },
          {
            path: 'received-mails/:id',
            name: 'received-mail-show',
            component: ReceivedMailShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'send-mails',
            name: 'send-mails',
            component: SendMails
          },
          {
            path: 'send-mails/:id',
            name: 'send-mail-show',
            component: SendMailShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'reservation-requests',
            name: 'reservation-requests',
            component: ReservationRequests
          },
          {
            path: 'reservation-requests/:id',
            name: 'reservation-request-show',
            component: ReservationRequestShow,
            props: route => ({ id: Number(route.params.id) })
          },
          {
            path: 'reservation-cancels',
            name: 'reservation-cancels',
            component: ReservationCancels
          },
          {
            path: 'reservation-cancels/:id',
            name: 'reservation-cancel-show',
            component: ReservationCancelShow,
            props: route => ({ id: Number(route.params.id) })
          }
        ]
      },
      {
        path: 'sample',
        name: 'sample',
        component: Sample,
        meta: { isPublic: true }
      }
    ]
  }
]

const router = new Router({ routes })

export const getPatientAllData = async to => {
  const patientId = Number(to.params.patientId)
  const acquiredPatientIds =
    store.getters['medicalRecords/getAcquiredPatientIds']
  if (acquiredPatientIds.includes(patientId)) return 'acquired'
  const result = await store.dispatch(
    'medicalRecords/getPatientAllData',
    patientId
  )
  if (result === 'success') {
    return 'success'
  } else {
    store.dispatch('petorelu/showGlobalPopup', {
      show: true,
      type: 'failure',
      title: '失敗',
      buttons: ['閉じる'],
      message: '患者情報の取得に失敗しました。',
      close: () => store.dispatch('petorelu/hideGlobalPopup')
    })
    return 'failure'
  }
}

export let navigateTo

window.addEventListener('popstate', () => {
  store.dispatch('petorelu/goBack')
})

export const beforeEach = async (to, from, next) => {
  const backClick = store.getters['petorelu/get'].backClick
  if (!navigateTo && backClick && to) navigateTo = to
  const ngLeave = store.getters['petorelu/get'].preventLeaveFlg
  const popupNgLeave = store.getters['petorelu/get'].popupPreventLeaveFlg
  if (ngLeave || popupNgLeave) {
    store.dispatch('petorelu/showLeaveDialog')
    if (navigateTo && backClick) {
      let path = router.history.current.fullPath
      if (router.mode == 'hash') {
        path = '#' + path
      }
      let state = {
        title: null,
        url: path
      }
      window.history.pushState(state, null, path)
      store.dispatch('petorelu/saveTo', navigateTo)
    } else {
      store.dispatch('petorelu/saveTo', to)
    }
    return
  }
  store.dispatch('petorelu/leaveReset')
  const isTokenRequiredPage = to.matched.some(record => !record.meta.isPublic)
  const loginStaffId = store.getters['auth/loginStaffId']
  if (to.meta.kartePageFlg && loginStaffId !== 0) {
    const result = await getPatientAllData(to)
    if (result === 'failure') return
  }
  if (isTokenRequiredPage && loginStaffId === 0) {
    // when reloading
    const storage = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY))
    const res = await mixinHandleSignIn('', '', storage.auth.token)
    if (to.meta.kartePageFlg) {
      const result = await getPatientAllData(to)
      if (result === 'failure') return
    }
    const lockRes = await lockCheck(from, to, next)
    if (lockRes === false) {
      return
    }
    navigateTo = ''
    store.dispatch('petorelu/cancelBack')
    res === true
      ? next()
      : next({ path: '/login', query: { redirect: to.fullPath } })
  } else if (
    to.matched.some(record => record.meta.requireAdmin) &&
    !store.getters['auth/adminFlg']
  ) {
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else {
    const lockRes = await lockCheck(from, to, next)
    if (lockRes === false) {
      return
    }
    navigateTo = ''
    store.dispatch('petorelu/cancelBack')
    next()
  }
}

const waitSocket = () => {
  return new Promise(resolve => {
    const startTime = Date.now()
    function checkSocket() {
      if (store.getters['auth/lockingClientKeys'] !== null) {
        resolve()
      } else if (Date.now() > startTime + 30000) {
        resolve()
      } else {
        window.setTimeout(checkSocket, 200)
      }
    }
    checkSocket()
  })
}

const getLockParams = page => {
  if (page.matched.some(record => record.meta.karteLock)) {
    if (page.matched.some(record => record.meta.paymentFlg)) {
      const medicalPaymentOriginalId = Number(
        page.params.medicalPaymentOriginalId
      )
      const medicalPayment = store.getters[
        'medicalPayments/getDataByOriginalId'
      ](medicalPaymentOriginalId)
      const medicalRecordOriginalId = medicalPayment.medicalRecordOriginalId
      const hospitalizationId = getHospitalizationId(medicalPayment)
      return [0, medicalRecordOriginalId, hospitalizationId]
    } else {
      if (page.matched.some(record => record.meta.newKarteFlg)) {
        const patientId = Number(page.params.patientId)
        return [patientId, 0, 0]
      } else {
        const medicalRecordOriginalId = Number(page.params.originalId)
        const medicalPaymentsByMROId = store.getters[
          'medicalPayments/getDataByMedicalRecordOriginalId'
        ](medicalRecordOriginalId)
        const medicalPayment =
          medicalPaymentsByMROId?.length > 0 ? medicalPaymentsByMROId[0] : null
        const hospitalizationId = getHospitalizationId(medicalPayment)
        return [0, medicalRecordOriginalId, hospitalizationId]
      }
    }
  } else {
    return [0, 0, 0]
  }
}

const getHospitalizationId = medicalPayment => {
  if (!medicalPayment) return 0
  const hospitalizations =
    store.getters['hospitalizations/getDataByPatientId'](
      medicalPayment.patientId
    ) ?? []
  const hospitalization = hospitalizations.find(v =>
    v.medicalPaymentOriginalIds.includes(medicalPayment.originalId)
  )
  return hospitalization ? hospitalization.id : 0
}

const lockCheck = async (from, to, next) => {
  const fromPageWithLock = from.matched.some(record => record.meta.karteLock)
  const toPageWithLock = to.matched.some(record => record.meta.karteLock)
  if (fromPageWithLock || toPageWithLock) {
    const [
      toPatientId,
      toMedicalRecordOriginalId,
      toHospitalizationId
    ] = getLockParams(to)
    const [
      fromPatientId,
      fromMedicalRecordOriginalId,
      fromHospitalizationId
    ] = getLockParams(from)
    const toSameKarte =
      fromPageWithLock &&
      toPageWithLock &&
      fromPatientId === toPatientId &&
      fromMedicalRecordOriginalId === toMedicalRecordOriginalId
    if (!toSameKarte) {
      if (toPageWithLock) {
        const toParams = {
          patientId: toPatientId,
          medicalRecordOriginalId: toMedicalRecordOriginalId,
          hospitalizationId: toHospitalizationId
        }
        await waitSocket()
        const isAnotherClientLocking = store.getters[
          'auth/isAnotherClientLocking'
        ](toParams)
        const canGoToLockedPage = store.getters['auth/canGoToLockedPage']
        if (isAnotherClientLocking && !canGoToLockedPage) {
          store.dispatch('petorelu/showKarteLockPopup', toParams)
          store.dispatch('petorelu/saveNextFunction', next)
          return false
        } else {
          store.dispatch('auth/lockKarte', toParams)
        }
        store.dispatch('auth/canGoToLockedPage', false)
      }
      if (fromPageWithLock && !toPageWithLock) {
        const fromParams = {
          patientId: fromPatientId,
          medicalRecordOriginalId: fromMedicalRecordOriginalId,
          hospitalizationId: fromHospitalizationId
        }
        await waitSocket()
        const isSelfLocking = !store.getters['auth/isAnotherClientLocking'](
          fromParams
        )
        if (isSelfLocking) {
          store.dispatch('auth/unlockKarte', fromParams)
        }
        store.dispatch('auth/setSelfLockParams', {
          patientId: 0,
          medicalRecordOriginalId: 0,
          hospitalizationId: 0
        })
      }
    }
  }
  return true
}

router.beforeEach((to, from, next) => beforeEach(to, from, next))

router.onError(error => {
  store.dispatch('petorelu/showGlobalPopup', {
    show: true,
    type: 'alert',
    title: '警告',
    buttons: ['閉じる'],
    message:
      '広告ブロッカーなどを使用していると正常に動作しない場合があります。\n広告ブロッカーなどを停止してからお試しください。',
    close: () => store.dispatch('petorelu/hideGlobalPopup')
  })
})

export default router
