import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { SESSION_STORAGE_KEY } from '@/utils/define'

import analytics from './modules/analytics'
import anicomCIdChecks from './modules/anicom_c_id_checks'
import anicomPatients from './modules/anicom_patients'
import anicomReports from './modules/anicom_reports'
import antibodies from './modules/antibodies'
import antibodyTypes from './modules/antibody_types'
import auth from './modules/auth'
import cares from './modules/cares'
import clinic from './modules/clinic'
import commonSetting from './modules/common_setting'
import diseaseClasses from './modules/disease_classes'
import diseases from './modules/diseases'
import display from './modules/display'
import estimateTemplates from './modules/estimate_templates'
import estimateTreatmentItems from './modules/estimate_treatment_items'
import estimates from './modules/estimates'
import examinationFormats from './modules/examination_formats'
import examinationResults from './modules/examination_results'
import examinationSpecies from './modules/examination_species'
import examinationTopicClasses from './modules/examination_topic_classes'
import examinationTopics from './modules/examination_topics'
import graces from './modules/graces'
import hospitalizations from './modules/hospitalizations'
import imageTags from './modules/image_tags'
import ipetChecks from './modules/ipet_checks'
import ipetPatients from './modules/ipet_patients'
import mailSetting from './modules/mail_setting'
import master from './modules/master'
import measurementResultItems from './modules/measurement_result_items'
import measurementResults from './modules/measurement_results'
import medicalContentImageTexts from './modules/medical_content_image_texts'
import medicalContentImages from './modules/medical_content_images'
import medicalContentTemplates from './modules/medical_content_templates'
import medicalContents from './modules/medical_contents'
import medicalPayments from './modules/medical_payments'
import medicalRecords from './modules/medical_records'
import medicalTreatmentItems from './modules/medical_treatment_items'
import medicineLargeClasses from './modules/medicine_large_classes'
import medicineMediumClasses from './modules/medicine_medium_classes'
import medicineSmallClasses from './modules/medicine_small_classes'
import medicines from './modules/medicines'
import microchipFacilities from './modules/microchip_facilities'
import notices from './modules/notices'
import openTimes from './modules/open_times'
import ownerTags from './modules/owner_tags'
import owners from './modules/owners'
import patientVaccinePeriods from './modules/patient_vaccine_periods'
import patientVaccines from './modules/patient_vaccines'
import patients from './modules/patients'
import payments from './modules/payments'
import petorelu from './modules/petorelu'
import previousMail from './modules/previous_mail'
import printSetting from './modules/print_setting'
import problems from './modules/problems'
import pyxosManage from './modules/pyxos_manage'
import receivedMails from './modules/received_mails'
import reservationAvailablePatterns from './modules/reservation_available_patterns'
import reservationColumnGroups from './modules/reservation_column_groups'
import reservationColumns from './modules/reservation_columns'
import reservationPurposes from './modules/reservation_purposes'
import reservationRequests from './modules/reservation_requests'
import reservationSetting from './modules/reservation_setting'
import reservations from './modules/reservations'
import richTextEditor from './modules/rich_text_editor'
import schemaImages from './modules/schema_images'
import sendMailTemplates from './modules/send_mail_templates'
import sendMails from './modules/send_mails'
import shiftPatterns from './modules/shift_patterns'
import shiftSetting from './modules/shift_setting'
import shifts from './modules/shifts'
import specialOpenTimes from './modules/special_open_times'
import specialReservationAvailablePatterns from './modules/special_reservation_available_patterns'
import species from './modules/species'
import staffReservationColumns from './modules/staff_reservation_columns'
import staffWorkingDates from './modules/staff_working_dates'
import staffs from './modules/staffs'
import timeTable from './modules/time_table'
import treatmentClasses from './modules/treatment_classes'
import treatmentLargeClasses from './modules/treatment_large_classes'
import treatmentSetItems from './modules/treatment_set_items'
import treatmentSets from './modules/treatment_sets'
import treatments from './modules/treatments'
import uploadImages from './modules/upload_images'
import vaccinations from './modules/vaccinations'
import vaccineDefaults from './modules/vaccine_defaults'
import vaccineReminders from './modules/vaccine_reminders'
import waitingStatuses from './modules/waiting_statuses'

Vue.use(Vuex)
Vue.config.productionTip = false
if (process.env.NODE_ENV === 'test') Vue.config.devtools = false

export default new Vuex.Store({
  modules: {
    analytics,
    anicomCIdChecks,
    anicomPatients,
    anicomReports,
    antibodies,
    antibodyTypes,
    auth,
    cares,
    clinic,
    commonSetting,
    diseaseClasses,
    diseases,
    display,
    estimateTemplates,
    estimateTreatmentItems,
    estimates,
    examinationFormats,
    examinationResults,
    examinationSpecies,
    examinationTopicClasses,
    examinationTopics,
    graces,
    hospitalizations,
    imageTags,
    ipetChecks,
    ipetPatients,
    mailSetting,
    master,
    measurementResultItems,
    measurementResults,
    medicalContentImageTexts,
    medicalContentImages,
    medicalContentTemplates,
    medicalContents,
    medicalPayments,
    medicalRecords,
    medicalTreatmentItems,
    medicineLargeClasses,
    medicineMediumClasses,
    medicineSmallClasses,
    medicines,
    microchipFacilities,
    notices,
    openTimes,
    ownerTags,
    owners,
    patientVaccinePeriods,
    patientVaccines,
    patients,
    payments,
    petorelu,
    previousMail,
    printSetting,
    problems,
    pyxosManage,
    receivedMails,
    reservationAvailablePatterns,
    reservationColumnGroups,
    reservationColumns,
    reservationPurposes,
    reservationRequests,
    reservationSetting,
    reservations,
    richTextEditor,
    schemaImages,
    sendMailTemplates,
    sendMails,
    shiftPatterns,
    shiftSetting,
    shifts,
    specialOpenTimes,
    specialReservationAvailablePatterns,
    species,
    staffReservationColumns,
    staffWorkingDates,
    staffs,
    timeTable,
    treatmentClasses,
    treatmentLargeClasses,
    treatmentSetItems,
    treatmentSets,
    treatments,
    uploadImages,
    vaccinations,
    vaccineDefaults,
    vaccineReminders,
    waitingStatuses
  },
  strict: false,
  plugins: [
    createPersistedState({
      key: SESSION_STORAGE_KEY,
      paths: ['auth.token'],
      storage: window.sessionStorage
    })
  ]
})
