import store from '@/store/store'
import moment from 'moment'

export const findMasterLaboratoryItemRange = ({
  masterLaboratoryItemCode,
  measurementResult
}) => {
  const patientId = measurementResult.patientId

  // 患者との紐づけが行われていない場合、該当するレコードは存在しない
  if (patientId === 0) {
    return null
  }

  const patient = store.getters['patients/getDataById'](patientId)
  const species = store.getters['species/getDataById'](patient?.speciesId)
  const masterLaboratoryItemSpecies = store.getters[
    'master/getMasterLaboratoryItemSpecies'
  ].find(v => v.name === species?.name)

  // 検査機器マスタに登録されている種別と、患者情報の種別が一致しない場合、該当するレコードは存在しない
  if (!masterLaboratoryItemSpecies) {
    return null
  }

  const filteredRangeRecords = store.getters[
    'master/getMasterLaboratoryItemRanges'
  ].filter(
    v =>
      v.masterLaboratoryItemCode === masterLaboratoryItemCode &&
      v.masterLaboratoryItemSpeciesCode === masterLaboratoryItemSpecies.code
  )

  if (filteredRangeRecords.length === 0) {
    return null
  }

  // 月齢の条件が無くてもレコードを1件に絞り込めた場合、それを返す
  if (filteredRangeRecords.length === 1) {
    return filteredRangeRecords[0]
  }

  // 月齢が無ければレコードが絞り込めない状態で、患者の生年月日が未登録の場合、該当するレコードは存在しない
  if (patient.birthday === '') {
    return null
  }

  const measurementDate = moment(measurementResult.date, 'YYYYMMDD')

  if (patient.birthday.length === 8) {
    const birthday = moment(patient.birthday, 'YYYYMMDD')
    return findRangeRecordByBirthday(
      filteredRangeRecords,
      measurementDate,
      birthday
    )
  } else if (patient.birthday.length === 6) {
    const startBirthday = moment(patient.birthday, 'YYYYMMDD').startOf('month')
    const endBirthday = moment(patient.birthday, 'YYYYMMDD').endOf('month')
    return findRangeRecordByDualBirthday(
      filteredRangeRecords,
      measurementDate,
      startBirthday,
      endBirthday
    )
  } else if (patient.birthday.length === 4) {
    const startBirthday = moment(patient.birthday, 'YYYYMMDD').startOf('years')
    const endBirthday = moment(patient.birthday, 'YYYYMMDD').endOf('years')
    return findRangeRecordByDualBirthday(
      filteredRangeRecords,
      measurementDate,
      startBirthday,
      endBirthday
    )
  } else {
    return null
  }
}

const findRangeRecordByBirthday = (
  filteredRangeRecords,
  measurementDate,
  birthday
) => {
  const monthAge = measurementDate.diff(birthday, 'months')
  const isUnderMinAge = v => v.minMonthAge !== -1 && v.minMonthAge > monthAge
  const isOverMaxAge = v => v.maxMonthAge !== -1 && v.maxMonthAge < monthAge
  return filteredRangeRecords.find(v => !isUnderMinAge(v) && !isOverMaxAge(v))
}

const findRangeRecordByDualBirthday = (
  filteredRangeRecords,
  measurementDate,
  startBirthday,
  endBirthday
) => {
  const startRecord = findRangeRecordByBirthday(
    filteredRangeRecords,
    measurementDate,
    startBirthday
  )
  const endRecord = findRangeRecordByBirthday(
    filteredRangeRecords,
    measurementDate,
    endBirthday
  )
  return startRecord.id === endRecord.id ? startRecord : null
}
