import store from '@/store/store'
import router from '@/router'
import { getAxiosObject, axiosObjectForReload } from '@/utils/library'
import { mixinSocketConnection } from '@/components/mixins/SocketManager'

const defaultStoreNames = [
  'analytics',
  'anicomPatients',
  'cares',
  'commonSetting',
  'display',
  'ipetPatients',
  'mailSetting',
  'microchipFacilities',
  'notices',
  'openTimes',
  'ownerTags',
  'owners',
  'patients',
  'previousMail',
  'receivedMails',
  'reservationAvailablePatterns',
  'reservationColumnGroups',
  'reservationColumns',
  'reservationPurposes',
  'reservationRequests',
  'reservationSetting',
  'reservations',
  'sendMailTemplates',
  'sendMails',
  'shiftPatterns',
  'shiftSetting',
  'shifts',
  'specialOpenTimes',
  'specialReservationAvailablePatterns',
  'species',
  'staffReservationColumns',
  'staffWorkingDates',
  'staffs',
  'waitingStatuses'
]

const karteStoreNames = [
  'anicomCIdChecks',
  'anicomReports',
  'diseaseClasses',
  'diseases',
  'estimateTemplates',
  'examinationFormats',
  'examinationResults',
  'examinationSpecies',
  'examinationTopicClasses',
  'examinationTopics',
  'graces',
  'hospitalizations',
  'imageTags',
  'ipetChecks',
  'medicalContentImageTexts',
  'medicalContentImages',
  'medicalContentTemplates',
  'medicalContents',
  'medicalPayments',
  'medicalRecords',
  'medicalTreatmentItems',
  'medicineLargeClasses',
  'medicineMediumClasses',
  'medicineSmallClasses',
  'medicines',
  'payments',
  'printSetting',
  'problems',
  'schemaImages',
  'treatmentClasses',
  'treatmentLargeClasses',
  'treatmentSetItems',
  'treatmentSets',
  'treatments',
  'uploadImages',
  'vaccineDefaults'
]

const noResDataKarteStoreNames = [
  'antibodies',
  'antibodyTypes',
  'estimateTreatmentItems',
  'estimates',
  'measurementResultItems',
  'measurementResults',
  'patientVaccinePeriods',
  'patientVaccines',
  'vaccineReminders'
]

export async function mixinHandleSignIn(email, password, token = '') {
  try {
    const localKey = localStorage.getItem('localKey') || ''
    const res = token
      ? await axiosObjectForReload(token).post('/login', {
          reloadFlg: true,
          localKey
        })
      : await getAxiosObject().post('/login', { email, password, localKey })
    store.dispatch('auth/authLogin', {
      token: res.data.token,
      tokenIssuingTime: res.data.tokenIssuingTime,
      staff: res.data.staff,
      backVersion: res.data.version,
      karteFlg: res.data.clinic.karteFlg,
      smsFlg: res.data.clinic.smsFlg,
      clientKey: res.data.clientKey
    })
    store.dispatch('master/setData', {
      prefectures: res.data.prefectures,
      jobs: res.data.jobs,
      shiftTypes: res.data.shiftTypes,
      publicHolidays: res.data.publicHolidays,
      reservationStatuses: res.data.reservationStatuses,
      noticeCategories: res.data.noticeCategories,
      masterLaboratoryCategories: res.data.masterLaboratoryCategories,
      masterLaboratoryItemRanges: res.data.masterLaboratoryItemRanges,
      masterLaboratoryItemSpecies: res.data.masterLaboratoryItemSpecies,
      masterLaboratoryItemSpecimenTypes:
        res.data.masterLaboratoryItemSpecimenTypes,
      masterLaboratoryItemUnits: res.data.masterLaboratoryItemUnits,
      masterLaboratoryItems: res.data.masterLaboratoryItems,
      masterLaboratoryMachines: res.data.masterLaboratoryMachines,
      patientSexes: res.data.patientSexes,
      contactTypes: res.data.contactTypes
    })
    store.dispatch('clinic/addClinic', {
      clinic: res.data.clinic,
      encryptedClinicId: res.data.encryptedClinicId
    })
    const notificationCounts = [
      { store: 'notices', count: 'noticesNoReadCount' },
      { store: 'receivedMails', count: 'receivedMailsNoReadCount' },
      { store: 'reservationRequests', count: 'reservationRequestsCount' },
      { store: 'reservations', count: 'cancelReservationsNoReadCount' }
    ]
    notificationCounts.forEach(nc => {
      store.dispatch(`${nc.store}/setCount`, res.data[nc.count])
    })
    let storeNames = defaultStoreNames
    if (res.data.clinic.karteFlg === 1) {
      storeNames = storeNames
        .concat(karteStoreNames)
        .concat(noResDataKarteStoreNames)
      store.dispatch('master/setKarteData', {
        unitTypes: res.data.unitTypes,
        vaccines: res.data.vaccines,
        vaccineSubNames: res.data.vaccineSubNames,
        schemaImageClasses: res.data.schemaImageClasses
      })
    }
    storeNames.forEach(storeName => {
      if (
        res.data.clinic.karteFlg === 0 ||
        (res.data.clinic.karteFlg === 1 &&
          !noResDataKarteStoreNames.includes(storeName))
      ) {
        store.dispatch(`${storeName}/setData`, res.data[storeName])
      }
      store.dispatch(`${storeName}/setUpdatedAt`, res.data.updatedAt)
    })
    await mixinSocketConnection()
    if (
      res.data.staff.supportLoginStartTime &&
      res.data.staff.supportLoginEndTime
    ) {
      const supportLoginEndTime = new Date(res.data.staff.supportLoginEndTime)
      const now = new Date()
      const stayTime = supportLoginEndTime.getTime() - now.getTime()
      window.setTimeout(() => {
        router.push({ name: 'login' })
      }, stayTime)
    }
    if (!localStorage.getItem('localKey')) {
      localStorage.setItem('localKey', res.data.localKey)
    }
    return true
  } catch (error) {
    return error.response?.data?.message === 'permission denied'
      ? error.response.data.extra
      : 'server error'
  }
}

export default {
  methods: {
    mixinHandleSignIn
  }
}
