<template>
  <div class="sample" v-if="devMode">
    <h1 class="title">サンプル</h1>
    <div class="components">
      <!-- コピペ用 -->
      <!-- <div class="component">
        <h2>●</h2>
      </div> -->
      <div class="component">
        <div class="karte-popup">
          <div>
            <base-button-border-orange>閉じる</base-button-border-orange>
          </div>
          <br />
          <div>
            <base-button-border-orange
              @click="
                $router.push(
                  '/main/karte/owners/201/patients/201/medical-records/new'
                )
              "
              >カルテ登録画面へ遷移</base-button-border-orange
            >
          </div>
          <br />
          <div>
            <base-button-border-orange
              @click="
                $router.push(
                  '/main/karte/owners/201/patients/201/medical-records/1/edit'
                )
              "
              >カルテ編集画面へ遷移</base-button-border-orange
            >
          </div>
          <br />
          <base-button-small-white
            :styles="{ width: '300px' }"
            @click="popupFlg = true"
            >カルテ・会計用ポップアップ表示</base-button-small-white
          >
          <popup
            v-if="popupFlg"
            :styles="{ width: '500px', height: '500px' }"
            :title="'タイトル表示'"
            :headerOrangeFlg="true"
            :buttonBorderOrangeFlg="true"
            @cancel="popupFlg = false"
            @click-close-mark="popupFlg = false"
          >
            <template v-slot:content
              ><div class="popup-slot-content">表示テスト</div>
            </template>
          </popup>
        </div>
      </div>
      <br />
      <div>
        <base-button-border-orange @click="apiSample"
          >API Sample</base-button-border-orange
        >
      </div>
      <br />
      <div class="component">
        <h2>●VaccineCheckListItem</h2>
        <div
          v-for="vaccine in dogVaccines"
          :key="vaccine.id"
          style="margin-bottom: 10px"
        >
          <vaccine-check-list-item
            :checkedId="vaccineId"
            :vaccine="vaccine"
            @check="selectVaccine"
          ></vaccine-check-list-item>
        </div>
      </div>
      <div class="component">
        <h2>●VaccineCheckList</h2>
        <vaccine-check-list
          :checkedId="vaccineId2"
          :vaccineTypeId="vaccineTypeId"
          @check="selectVaccine2"
        ></vaccine-check-list>
      </div>
      <div class="component">
        <h2>●AccordionListRow</h2>
        <accordion-list-row
          :parent="{
            id: 1,
            name: 'サンプル1',
            layerNumber: 1,
            children: []
          }"
        />
        <br />
        <accordion-list-row
          :parent="{ id: 1, name: 'サンプル2', layerNumber: 1 }"
        />
      </div>
      <div class="component">
        <h2>●AccordionList</h2>
        <accordion-list :data="displayMedicineData" @click-row="clickRow" />
      </div>
      <div class="component">
        <h2>●MedicalRecordImageUploader</h2>
        <medical-record-image-uploader
          :image="image"
          @input="image = $event"
        ></medical-record-image-uploader>
        <br />
        <medical-record-image-uploader
          :image="image"
          :styles="{ width: '400px', height: '70px' }"
          @input="image = $event"
        ></medical-record-image-uploader>
        <br />
        <img :src="image" class="image" />
        <br />
        <button v-if="image !== null" @click="image = null">画像削除</button>
      </div>
      <div class="component">
        <h2>●カルテ画像ポップアップ</h2>
        <div class="image-popup">
          <base-button-small-white
            :styles="{ width: '300px' }"
            @click="imagePopupFlg = true"
            >カルテ画像登録ポップアップ表示</base-button-small-white
          >
          <upload-image-popup
            v-if="imagePopupFlg"
            @close="imagePopupFlg = false"
            @decide=";(uploadImage = $event), (imagePopupFlg = false)"
          ></upload-image-popup>
          <br />
          <div v-if="uploadImage.image !== null">
            <div>画像タイトル：{{ uploadImage.title }}</div>
            <img :src="uploadImage.image" class="image" />
            <br />
            <button @click="uploadImage = { tiele: '', image: null }">
              画像削除
            </button>
          </div>
        </div>
      </div>
      <div class="component">
        <h2>●シェーマポップアップ</h2>
        <div class="image-popup">
          <base-button-small-white
            :styles="{ width: '300px' }"
            @click="schemaPopupFlg = true"
            >シェーマポップアップ表示</base-button-small-white
          >
          <schema-popup v-if="schemaPopupFlg" @close="schemaPopupFlg = false" />
        </div>
      </div>
      <div class="component">
        <h2>●テンプレートリストポップアップ</h2>
        <base-button-small-white
          :styles="{ width: '300px' }"
          @click="medicalContentTemplatesPopupFlg = true"
          >テンプレートリストポップアップ表示</base-button-small-white
        >
        <medical-content-templates-popup
          v-if="medicalContentTemplatesPopupFlg"
          @close="medicalContentTemplatesPopupFlg = false"
        />
      </div>
      <div class="component">
        <h2>●DataTable</h2>
        <div class="sample-table">
          <data-table
            :keys="dataTableData.keys"
            :headers="dataTableData.headers"
            :items="sampleMedicines"
            :headerCellStyles="dataTableData.headerCellStyles"
            :bodyCellStyles="dataTableData.bodyCellStyles"
            :bodyStyle="{ maxHeight: '90px' }"
            :scrollFlg="true"
          />
        </div>
        <br />
        <div class="sample-table">
          <data-table
            :keys="dataTableData.keys"
            :headers="dataTableData.headers"
            :items="sampleMedicines"
            :headerCellStyles="dataTableData.headerCellStyles"
            :bodyCellStyles="dataTableData.bodyCellStyles"
            :stripeFlg="true"
          />
        </div>
      </div>
      <div class="component">
        <h2>●検査登録ポップアップ</h2>
        <div class="examination-popup">
          <base-button-small-white
            :styles="{ width: '300px' }"
            @click="examinationPopupFlg = true"
            >検査登録ポップアップ表示</base-button-small-white
          >
          <examination-list-popup
            v-if="examinationPopupFlg"
            @close="examinationPopupFlg = false"
          ></examination-list-popup>
        </div>
      </div>
      <div class="component">
        <h2>予防接種証明書印刷</h2>
        <base-button-border-orange @click="printVaccinationFlg = true"
          >予防接種証<br />明書印刷</base-button-border-orange
        >
        <print-options-popup
          class="popup"
          v-if="printVaccinationFlg"
          :dataType="'vaccinationCertificate'"
          :owner="getOwnerById(201)"
          :patient="displayPatient"
          :data="vaccineData"
          @close="() => (printVaccinationFlg = false)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import Popup from '@/components/popups/Popup'
import VaccineCheckListItem from '@/components/parts/atoms/VaccineCheckListItem'
import VaccineCheckList from '@/components/parts/molecules/VaccineCheckList'
import AccordionListRow from '@/components/parts/molecules/AccordionListRow'
import AccordionList from '@/components/parts/organisms/AccordionList'
import MedicalRecordImageUploader from '@/components/parts/atoms/MedicalRecordImageUploader'
import UploadImagePopup from '@/components/popups/UploadImagePopup'
import DataTable from '@/components/parts/organisms/DataTable'
import ExaminationListPopup from '@/components/popups/ExaminationListPopup'
import MedicalContentTemplatesPopup from '@/components/popups/MedicalContentTemplatesPopup'
import SchemaPopup from '@/components/popups/SchemaPopup'
import PrintOptionsPopup from '@/components/popups/PrintOptionsPopup'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'Sample',

  components: {
    BaseButtonSmallWhite,
    BaseButtonBorderOrange,
    Popup,
    VaccineCheckListItem,
    VaccineCheckList,
    AccordionListRow,
    AccordionList,
    MedicalRecordImageUploader,
    UploadImagePopup,
    DataTable,
    ExaminationListPopup,
    SchemaPopup,
    MedicalContentTemplatesPopup,
    PrintOptionsPopup
  },

  data() {
    return {
      popupFlg: false,
      vaccine: {
        id: 1,
        name: 'ワクチン'
      },
      vaccineId: 0,
      vaccineId2: 0,
      vaccineTypeId: 2,
      image: null,
      imagePopupFlg: false,
      uploadImage: {
        title: '',
        image: null
      },
      dataTableData: {
        headers: ['ID', '薬剤物品名', '単価', '初期値', 'メモ'],
        keys: ['id', 'name', 'priceRate', 'defaultVolume', 'memo'],
        headerCellStyles: [
          { width: '10%' },
          { width: '30%', textAlign: 'left', paddingLeft: '8px' },
          { width: '15%' },
          { width: '15%' },
          { width: '30%' }
        ],
        bodyCellStyles: [
          { width: '10%', textAlign: 'center' },
          { width: '30%', textAlign: 'left' },
          { width: '15%', color: 'blue' },
          { width: '15%' },
          { width: '30%', fontSize: '10px' }
        ]
      },
      examinationPopupFlg: false,
      schemaPopupFlg: false,
      medicalContentTemplatesPopupFlg: false,
      printVaccinationFlg: false,
      patientId: 201
    }
  },

  computed: {
    ...mapGetters({
      getMasterData: 'master/getData',
      medicinesLargeClasses: 'medicineLargeClasses/getData',
      medicinesMediumClassesByLargeOriginalId:
        'medicineMediumClasses/getDataByMedicineLargeClassOriginalId',
      medicinesSmallClassesByMediumOriginalId:
        'medicineSmallClasses/getDataByMedicineMediumClassOriginalId',
      medicinesBySmallOriginalId:
        'medicines/getDataByMedicineSmallClassOriginalId',
      medicines: 'medicines/getData',
      getOwnerById: 'owners/getDataById',
      prefectures: 'master/getPrefectures',
      getPatientById: 'patients/getDataById',
      getSpeciesById: 'species/getDataById',
      patientSexes: 'master/selectPatientSexesZero',
      getStaffById: 'staffs/getDataById',
      patientVaccinesByPatientId: 'patientVaccines/getDataByPatientId',
      getAntibodyById: 'antibodies/getDataById'
    }),
    devMode() {
      return process.env.NODE_ENV === 'development'
    },
    vaccines() {
      return this.getMasterData('vaccines')
    },
    dogVaccines() {
      return this.vaccines.filter(
        v => v.dogFlg === 1 && (v.id === 5 || v.id === 6)
      )
    },
    displayMedicineData() {
      return this.medicinesLargeClasses
        .filter(largeClass => largeClass.showFlg)
        .map(largeClass => {
          const mediumClasses = this.medicinesMediumClassesByLargeOriginalId(
            largeClass.originalId
          )
          const children = mediumClasses
            ? mediumClasses
                .filter(mediumClass => mediumClass.showFlg)
                .map(mediumClass => {
                  const smallClasses = this.medicinesSmallClassesByMediumOriginalId(
                    mediumClass.originalId
                  )
                  const grandchildren = smallClasses
                    ? smallClasses
                        .filter(smallClass => smallClass.showFlg)
                        .map(smallClass => {
                          const medicines = this.medicinesBySmallOriginalId(
                            smallClass.originalId
                          )
                          return {
                            ...smallClass,
                            children: medicines
                              ? medicines
                                  .filter(medicine => medicine.showFlg)
                                  .map(medicine => {
                                    return { ...medicine, layerNumber: 4 }
                                  })
                              : [],
                            layerNumber: 3
                          }
                        })
                    : []
                  return {
                    ...mediumClass,
                    children: grandchildren,
                    layerNumber: 2
                  }
                })
            : []
          return { ...largeClass, children, layerNumber: 1 }
        })
    },
    sampleMedicines() {
      return this.medicines.map(v => {
        if (v.id === 1) {
          v.prescription = `${'あ'.repeat(255)}`
        }
        return v
      })
    },
    displayPatient() {
      const patient = { ...this.getPatientById(this.patientId) }
      patient.speciesName = this.getSpeciesById(patient.speciesId).name
      patient.sexName = this.patientSexes.find(v => v.id === patient.sex).name
      return patient
    },
    vaccineData() {
      const patientVaccine = this.patientVaccinesByPatientId(this.patientId)[0]
      const antibody = this.getAntibodyById(2) // シードデータにあるデータ - レントンの狂犬病
      const staff = antibody.staffId
        ? this.getStaffById(antibody.staffId)
        : undefined
      const staffName = staff ? staff.lastName + ' ' + staff.firstName : ''
      return {
        name: this.vaccines.find(v => v.id === patientVaccine.vaccineId)?.name,
        date: moment(antibody.date).format('YYYY年MM月DD日'),
        nextDate: moment(antibody.date)
          .add(1, 'Y')
          .format('YYYY年MM月DD日'),
        staffName,
        memo: antibody.memo
      }
    }
  },

  created() {},

  methods: {
    async apiSample() {
      const data = {
        id: 2,
        name: 'sample',
        content: '<div>あああああ</div>'
      }
      const res = await this.$store.dispatch(
        'medicalContentTemplates/update',
        data
      )
    },
    selectVaccine(id) {
      this.vaccineId = id
    },
    selectVaccine2(id) {
      this.vaccineId2 = id
    },
    clickRow(val) {}
  }
}
</script>

<style lang="scss" scoped>
.sample {
  width: 100%;
  overflow: auto;
  padding-bottom: 100px;
  > .components {
    display: flex;
    flex-direction: column;
    align-items: center;
    > .component {
      margin-bottom: 30px;
      > .sample-table {
        width: 800px;
      }
    }
  }
}
.popup-slot-content {
  text-align: left;
  margin: 20px 0 0 20px;
  font-weight: bold;
}
</style>
