import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import VCalendar from 'v-calendar-ie11'
import VueHead from 'vue-head'

import sanitizeHTML from 'sanitize-html'
sanitizeHTML.defaults.allowedAttributes.div = ['style']
sanitizeHTML.defaults.allowedAttributes.span = ['style']

Vue.config.productionTip = false
Vue.use(VCalendar)
Vue.use(VueHead)
Vue.use(require('vue-shortkey'))
Vue.prototype.$sanitize = sanitizeHTML

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
