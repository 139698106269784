import { getAxiosObject } from '@/utils/library'
import { mutationUpdateAfter } from '@/utils/update_after'
import {
  setIndexedData2,
  updateIndexedData,
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'
import _ from 'lodash'
import { compareShowId } from '@/utils/sort_show_id'
import { toStateName } from '@/utils/convert_string'
import { decodeBase64fromBuffer } from '@/utils/base64'

const dataName = 'patients'
const keys = ['ownerId']

const state = {
  patients: [],
  dataIndexedById: {},
  updatedAt: '20101010101010',
  acquiredPatientIdsForCheckDuplicateOwner: new Set()
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataIncludeDel', 'getDataById', 'getUpdatedAt'],
    { filterDel: true, getDataBy: keys }
  ),
  getAcquiredPatientIdsForCheckDuplicateOwner(state) {
    return state.acquiredPatientIdsForCheckDuplicateOwner
  }
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create(
    { commit },
    {
      patient,
      anicomPatient = null,
      ipetPatient = null,
      reservation,
      mailFlgs = { new: true, change: true, cancel: true },
      patientVaccines = [],
      patientVaccinePeriodGroups = []
    }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = reservation
        ? await axiosObject.post('/' + dataName, {
            patient,
            anicomPatient,
            ipetPatient,
            reservation,
            mailFlgs,
            patientVaccines,
            patientVaccinePeriodGroups
          })
        : await axiosObject.post('/' + dataName, {
            patient,
            anicomPatient,
            ipetPatient,
            patientVaccines,
            patientVaccinePeriodGroups
          })
      if (res.status === 200) {
        commit('updateAfter', [res.data.patient])
        if (res.data.anicomPatient) {
          commit('anicomPatients/updateAfter', [res.data.anicomPatient], {
            root: true
          })
        }
        if (res.data.ipetPatient) {
          commit('ipetPatients/updateAfter', [res.data.ipetPatient], {
            root: true
          })
        }
        if (res.data.reservations) {
          commit('reservations/updateAfter', res.data.reservations, {
            root: true
          })
        }
        if (res.data.sendMail) {
          commit('sendMails/updateAfter', [res.data.sendMail], {
            root: true
          })
        }
        if (res.data.patientVaccines?.length > 0) {
          commit('patientVaccines/updateAfter', res.data.patientVaccines, {
            root: true
          })
          commit(
            'patientVaccinePeriods/updateAfter',
            res.data.patientVaccinePeriods,
            { root: true }
          )
        }
        return true
      }
    } catch (error) {
      if (error.response?.data?.message === 'already used') {
        commit('updateAfter', [error.response.data.extra.patient])
        return `used ${error.response.data.extra.field}`
      } else if (error.response?.data?.message === 'no data') {
        return `no ${error.response.data.extra}`
      } else if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('reservations/setData', error.response.data.extra.data, {
            root: true
          })
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update(
    { commit },
    {
      patient,
      anicomPatient = null,
      ipetPatient = null,
      patientVaccines = [],
      patientVaccinePeriodGroups = [],
      reservations
    }
  ) {
    try {
      const axiosObject = getAxiosObject()
      const res = reservations
        ? await axiosObject.put('/' + dataName, {
            patient,
            anicomPatient,
            ipetPatient,
            patientVaccines,
            patientVaccinePeriodGroups,
            reservations
          })
        : await axiosObject.put('/' + dataName, {
            patient,
            anicomPatient,
            ipetPatient,
            patientVaccines,
            patientVaccinePeriodGroups
          })
      if (res.status === 200) {
        commit('updateAfter', [res.data.patient])
        if (res.data.anicomPatients?.length > 0) {
          commit('anicomPatients/updateAfter', res.data.anicomPatients, {
            root: true
          })
        }
        if (res.data.ipetPatients?.length > 0) {
          commit('ipetPatients/updateAfter', res.data.ipetPatients, {
            root: true
          })
        }
        if (res.data.patientVaccines?.length > 0) {
          commit('patientVaccines/updateAfter', res.data.patientVaccines, {
            root: true
          })
          commit(
            'patientVaccinePeriods/updateAfter',
            res.data.patientVaccinePeriods,
            { root: true }
          )
        }
        if (res.data.reservations?.length > 0) {
          commit('reservations/updateAfter', res.data.reservations, {
            root: true
          })
        }
        return true
      }
    } catch (error) {
      if (error.response?.data?.message === 'already used') {
        commit('updateAfter', [error.response.data.extra.patient])
        return `used ${error.response.data.extra.field}`
      } else if (error.response?.data?.message === 'no data') {
        return `no ${error.response.data.extra}`
      } else if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        if (
          error.response?.data?.extra ===
          'patient death date before last medicalRecord date'
        ) {
          return 'patient death date before last medicalRecord date'
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit, rootGetters }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/' + dataName, { data: { id } })
      if (res.status === 200) {
        commit('updateAfter', [res.data.patient])
        const karteFlg = rootGetters['auth/karteFlg']
        if (karteFlg) {
          const stores = [
            'anicomCIdChecks',
            'anicomPatients',
            'antibodies',
            'antibodyTypes',
            'estimateTreatmentItems',
            'estimates',
            'examinationResults',
            'hospitalizations',
            'ipetPatients',
            'measurementResultItems',
            'measurementResults',
            'medicalContentImageTexts',
            'medicalContentImages',
            'medicalContents',
            'medicalPayments',
            'medicalRecords',
            'medicalTreatmentItems',
            'patientVaccinePeriods',
            'patientVaccines',
            'payments',
            'problems',
            'reservationRequests',
            'reservations',
            'uploadImages',
            'vaccineReminders'
          ]
          stores.forEach(store => {
            const storeData = res.data[store]
            if (storeData.length > 0) {
              commit(`${store}/updateAfter`, storeData, { root: true })
            }
          })
        } else {
          if (res.data.anicomPatients.length > 0) {
            commit('anicomPatients/updateAfter', res.data.anicomPatients, {
              root: true
            })
          }
          if (res.data.ipetPatients.length > 0) {
            commit('ipetPatients/updateAfter', res.data.ipetPatients, {
              root: true
            })
          }
          if (res.data.reservations.length > 0) {
            commit('reservations/updateAfter', res.data.reservations, {
              root: true
            })
          }
          if (res.data.reservationRequests.length > 0) {
            commit(
              'reservationRequests/updateAfter',
              res.data.reservationRequests,
              { root: true }
            )
          }
        }
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'currently used') {
          return `used in ${error.response.data.extra}`
        } else {
          if (error.response.data.extra?.data) {
            commit('setData', error.response.data.extra.data)
          }
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },
  async get({ commit, rootGetters }, { id, ownerId }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get(`/${dataName}`, { params: { id } })
      if (res.status === 200) {
        commit('owners/insertPatientIds', [id], { root: true })
        if (res.data.patientImage) {
          commit('setImages', {
            patientsImages: [res.data.patientImage],
            ownerId
          })
        }
        if (res.data.reservations.length > 0) {
          commit('reservations/updateAfter', res.data.reservations, {
            root: true
          })
        }
        const karteFlg = rootGetters['auth/karteFlg']
        if (karteFlg) {
          const stores = [
            'patientVaccines',
            'patientVaccinePeriods',
            'antibodies',
            'antibodyTypes',
            'medicalRecords'
          ]
          stores.forEach(store => {
            const storeData = res.data[store]
            if (storeData.length > 0)
              commit(`${store}/updateAfter`, storeData, { root: true })
          })
        }
        return true
      }
    } catch (error) {
      return false
    }
  },
  async getDataForCheckDuplicateOwner({ commit }, patientId) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get(
        '/' + dataName + '/data-for-check-duplicate-owner',
        { params: { patientId } }
      )
      if (res.status === 200) {
        commit('insertPatientId', patientId)
        const stores = [
          'patientVaccines',
          'antibodies',
          'vaccineReminders',
          'estimates',
          'ipetChecks',
          'anicomCIdChecks'
        ]
        stores.forEach(store => {
          const storeData = res.data[store]
          if (storeData.length > 0)
            commit(`${store}/updateAfter`, storeData, { root: true })
        })
        return true
      }
    } catch {
      return false
    }
  },
  setImages({ commit }, obj) {
    commit('setImages', obj)
  },
  async checkAnicomValidity({ commit }, { patientId }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/anicom/check-validity', {
        patientId
      })
      if (res.data.anicomCIdCheck) {
        commit('anicomCIdChecks/updateAfter', [res.data.anicomCIdCheck], {
          root: true
        })
      }
      if (res.data.anicomPatients?.length > 0) {
        commit('anicomPatients/updateAfter', res.data.anicomPatients, {
          root: true
        })
      }
      return { anicomCIdCheck: res.data.anicomCIdCheck }
    } catch (error) {
      let errMsg = ''
      if (error.response?.data?.message === 'no data in clinic') {
        errMsg = 'no data in clinic'
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
      } else if (error.response?.data?.message === 'no data') {
        errMsg = `no ${error.response.data.extra}`
      } else if (error.response?.data?.message === 'invalid data') {
        errMsg = error.response.data.extra
      } else if (error.response?.data?.message) {
        errMsg = error.response.data.message
      } else {
        errMsg = 'server error'
      }
      return { errMsg }
    }
  },
  async checkIpetValidity({ commit }, { patientId, insurance, guDate }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/ipet/check-validity', {
        patientId,
        insurance,
        guDate
      })
      if (res.data.ipetCheck) {
        commit('ipetChecks/updateAfter', [res.data.ipetCheck], {
          root: true
        })
      }
      return { ipetCheck: res.data.ipetCheck }
    } catch (error) {
      let errMsg = ''
      if (error.response?.data?.message === 'no data in clinic') {
        errMsg = 'no data in clinic'
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
      } else if (error.response?.data?.message === 'no data') {
        errMsg = `no ${error.response.data.extra}`
      } else if (error.response?.data?.message === 'invalid data') {
        errMsg = error.response.data.extra
      } else if (error.response?.data?.message) {
        errMsg = error.response.data.message
      } else {
        errMsg = 'server error'
      }
      return { errMsg }
    }
  }
}

const setDataByOwnerId = state => {
  const notDeletedPatients = state[dataName]
    .filter(v => v.delFlg === 0)
    .sort((a, b) => compareShowId(a.showId, b.showId))
  state.dataByOwnerId = _.groupBy(notDeletedPatients, 'ownerId')
}

export const changeDataByOwnerId = (state, patients) => {
  if (state.dataByOwnerId === null) {
    return mutations.setDataByOwnerId(state)
  }
  patients.forEach(patient => {
    if (patient.delFlg === 0) {
      if (Array.isArray(state.dataByOwnerId[patient.ownerId])) {
        const index = state.dataByOwnerId[patient.ownerId].findIndex(
          p => p.id === patient.id
        )
        if (index !== -1) {
          state.dataByOwnerId[patient.ownerId].splice(index, 1, patient)
          state.dataByOwnerId[patient.ownerId].sort((a, b) =>
            compareShowId(a.showId, b.showId)
          )
        } else {
          state.dataByOwnerId[patient.ownerId].push(patient)
          state.dataByOwnerId[patient.ownerId].sort((a, b) =>
            compareShowId(a.showId, b.showId)
          )
        }
      } else {
        state.dataByOwnerId[patient.ownerId] = [patient]
      }
    } else {
      if (Array.isArray(state.dataByOwnerId[patient.ownerId])) {
        const deleteIndex = state.dataByOwnerId[patient.ownerId].findIndex(
          p => p.id === patient.id
        )
        if (deleteIndex !== -1) {
          state.dataByOwnerId[patient.ownerId].splice(deleteIndex, 1)
          if (state.dataByOwnerId[patient.ownerId].length === 0) {
            delete state.dataByOwnerId[patient.ownerId]
          }
        }
      }
    }
  })
}

const mutations = {
  ...commonMutations(dataName, ['setUpdatedAt'], { indexedById: true }),
  setData(state, patients) {
    state[dataName] = patients
    state.dataIndexedById = {}
    state.dataByOwnerId = null
  },
  setImages(state, obj) {
    if (Object.keys(state.dataIndexedById).length === 0) {
      mutations.setIndexed(state)
    }
    if (state.dataByOwnerId === null) {
      mutations.setDataByOwnerId(state)
    }
    obj.patientsImages.forEach(v => {
      const image = v.image ? Buffer.from(v.image, 'base64').toString() : null
      state[dataName].find(p => p.id === v.id).image = image
      state.dataIndexedById[v.id].image = image
      state.dataByOwnerId[obj.ownerId].find(x => x.id === v.id).image = image
    })
  },
  setIndexed(state) {
    setIndexedData2(state, dataName)
  },
  setDataByOwnerId(state) {
    setDataByOwnerId(state)
  },
  updateAfter(state, data) {
    data = decodeBase64fromBuffer(data)
    mutationUpdateAfter(state, data, dataName)
    updateIndexedData(state, data, dataName)
    changeDataByOwnerId(state, data)
  },
  insertPatientId(state, patientId) {
    state.acquiredPatientIdsForCheckDuplicateOwner.add(patientId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
