<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div class="print-options-popup" @keyup.esc.stop="cancel" tabindex="-1">
      <popup
        :buttons="['閉じる', '印刷']"
        @cancel="cancel"
        @decision="makePdf"
        @click-close-mark="cancel"
      >
        <template v-slot:content>
          <div class="content">
            <div class="title">{{ title }}</div>
            <div class="form">
              <div class="form-item" data-test="paper-size">
                <div class="label">用紙サイズ</div>
                <div class="input-field">
                  <div class="radio-buttons">
                    <base-radio-button
                      v-for="datum in paperSizeData"
                      :key="'paper-size' + datum.id"
                      :option="datum"
                      v-model="printOptions.paperSize"
                    />
                  </div>
                </div>
              </div>
              <div
                class="form-item"
                data-test="receipt-date"
                v-if="isUsedPrintOption('receiptDate')"
              >
                <div class="label">発行日</div>
                <div class="input-field">
                  <v-date-picker
                    :input-props="{ class: 'v-date-picker', readonly: true }"
                    v-model="printOptions.receiptDate"
                  />
                </div>
              </div>
              <div
                class="form-item"
                data-test="owner-name"
                v-if="isUsedPrintOption('ownerName')"
              >
                <div class="label">飼主名</div>
                <div class="input-field">
                  <base-text-box
                    :maxlength="31"
                    v-model="printOptions.ownerName"
                  />
                </div>
              </div>
              <div
                class="form-item"
                data-test="proviso"
                v-if="isUsedPrintOption('proviso')"
              >
                <div class="label">但し書き</div>
                <div class="input-field">
                  <base-text-box
                    :maxlength="31"
                    v-model="printOptions.proviso"
                  />
                </div>
              </div>
              <div
                class="form-item"
                data-test="clinic-tel"
                v-if="isUsedPrintOption('showClinicTel')"
              >
                <div class="label">医院電話番号</div>
                <div class="input-field">
                  <div class="radio-buttons">
                    <base-radio-button
                      v-for="datum in printClinicTelData"
                      :key="'clinic-tel-flg' + datum.id"
                      :option="datum"
                      v-model="printOptions.showClinicTel"
                    />
                  </div>
                </div>
              </div>
              <div
                class="form-item"
                data-test="clinic-email"
                v-if="isUsedPrintOption('showClinicEmail')"
              >
                <div class="label">医院メールアドレス</div>
                <div class="input-field">
                  <div class="radio-buttons">
                    <base-radio-button
                      v-for="datum in printClinicEmailData"
                      :key="'clinic-email-flg' + datum.id"
                      :option="datum"
                      v-model="printOptions.showClinicEmail"
                    />
                  </div>
                </div>
              </div>
              <div
                class="form-item"
                data-test="clinic-address"
                v-if="isUsedPrintOption('showClinicAddress')"
              >
                <div class="label">医院住所</div>
                <div class="input-field">
                  <div class="radio-buttons">
                    <base-radio-button
                      v-for="datum in printClinicAddressData"
                      :key="'clinic-address-flg' + datum.id"
                      :option="datum"
                      v-model="printOptions.showClinicAddress"
                    />
                  </div>
                </div>
              </div>
              <div
                class="form-item"
                data-test="invoice-number"
                v-if="showFormItemInvoiceNumber"
              >
                <div class="label">登録番号</div>
                <div class="input-field">
                  <div class="radio-buttons">
                    <base-radio-button
                      v-for="datum in invoiceNumberData"
                      :key="'invoice-number' + datum.id"
                      :option="datum"
                      v-model="printOptions.showInvoiceNumber"
                    />
                  </div>
                </div>
              </div>
              <div
                class="form-item"
                data-test="barcode"
                v-if="isUsedPrintOption('showBarcode')"
              >
                <div class="label">自己負担額バーコード</div>
                <div class="input-field">
                  <div class="radio-buttons">
                    <base-radio-button
                      v-for="datum in barcodeData"
                      :key="'barcode-flg' + datum.id"
                      :option="datum"
                      :value="printOptions.showBarcode"
                      @input="inputShowBarcode"
                    />
                  </div>
                </div>
              </div>
              <div
                class="form-item"
                data-test="next-reservation"
                v-if="isUsedPrintOption('showNextReservation')"
              >
                <div class="label">次回の予約</div>
                <div class="input-field">
                  <div class="radio-buttons">
                    <base-radio-button
                      v-for="datum in nextReservationData"
                      :key="'next-reservation-flg' + datum.id"
                      :option="datum"
                      v-model="printOptions.showNextReservation"
                    />
                  </div>
                </div>
              </div>
              <div
                class="form-item"
                data-test="printing-copy"
                v-if="isUsedPrintOption('printCopy')"
              >
                <div class="label">控えの印刷</div>
                <div class="input-field">
                  <base-check-box
                    class="checkbox"
                    v-model="printOptions.printCopy"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </popup>
      <announce-popup
        v-if="popupFlg"
        v-bind="popup"
        @close="closePopup"
        @cancel="popupFlg = false"
        @decision="$emit('close')"
        >{{ popup.message }}</announce-popup
      >
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { printReceipt } from '@/utils/print_receipt'
import { printEstimate } from '@/utils/print_estimate'
import { printMedicalPayment } from '@/utils/print_medical_payment'
import { printPrescription } from '@/utils/print_prescription'
import { printExamination } from '@/utils/print_examination'
import { printVaccinationCertificate } from '@/utils/print_vaccination_certificate'
import { makeBirthdayJp, calcAge } from '@/utils/patient_info'
import { makeFilteredResultGroupData } from '@/utils/examination_utils'
import { mapGetters } from 'vuex'
import { FocusTrap } from 'focus-trap-vue'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'PrintOptionsPopup',

  components: {
    Popup,
    BaseRadioButton,
    BaseCheckBox,
    BaseTextBox,
    AnnouncePopup,
    FocusTrap
  },

  mixins: [CheckPopupInputDifference],

  props: {
    dataType: { type: String, required: true },
    data: { type: [Object, Array], required: true }, // 予防接種履歴のテーブルから証明書を印刷する場合は配列
    owner: { type: Object },
    patient: { type: Object },
    removingPrintOptionKeys: { type: Array, default: () => [] }
  },

  data() {
    return {
      printOptions: {},
      initialPrintOptions: {},
      printOptionKeysByType: {
        estimate: ['paperSize', 'showClinicName', 'printCopy'],
        examination: ['paperSize', 'showClinicName'],
        medicalPayment: [
          'paperSize',
          'showClinicName',
          'showClinicTel',
          'showClinicEmail',
          'showClinicAddress',
          'showInvoiceNumber',
          'showBarcode',
          'showNextReservation'
        ],
        prescription: ['paperSize', 'showClinicName'],
        receipt: [
          'paperSize',
          'receiptDate',
          'ownerName',
          'proviso',
          'showClinicName',
          'showInvoiceNumber',
          'printCopy'
        ],
        vaccinationCertificate: ['paperSize', 'showClinicName']
      },
      usingPrintOptionKeys: [],
      showFormItemInvoiceNumber: false,
      paperSizeData: [
        { id: -1, eachValue: 'A4', labelName: 'A4' },
        { id: -2, eachValue: 'B5', labelName: 'B5' },
        { id: -3, eachValue: 'A5', labelName: 'A5' }
      ],
      printClinicTelData: [
        { id: -4, eachValue: true, labelName: '表示する' },
        { id: -5, eachValue: false, labelName: '表示しない' }
      ],
      printClinicEmailData: [
        { id: -6, eachValue: true, labelName: '表示する' },
        { id: -7, eachValue: false, labelName: '表示しない' }
      ],
      printClinicAddressData: [
        { id: -8, eachValue: true, labelName: '表示する' },
        { id: -9, eachValue: false, labelName: '表示しない' }
      ],
      invoiceNumberData: [
        { id: -10, eachValue: true, labelName: '表示する' },
        { id: -11, eachValue: false, labelName: '表示しない' }
      ],
      barcodeData: [
        { id: -12, eachValue: true, labelName: '表示する' },
        { id: -13, eachValue: false, labelName: '表示しない' }
      ],
      nextReservationData: [
        { id: -14, eachValue: true, labelName: '表示する' },
        { id: -15, eachValue: false, labelName: '表示しない' }
      ],
      popupFlg: false,
      popup: {},
      popupCloseAction: null
    }
  },

  computed: {
    ...mapGetters({
      clinic: 'clinic/getData',
      printSetting: 'printSetting/getData',
      reservations: 'reservations/getData',
      getMasterDatum: 'master/getDataById',
      getMedicalRecordByOriginalId: 'medicalRecords/getDataByOriginalId',
      getMedicine: 'medicines/getDataById',
      getMedicineByOriginalId: 'medicines/getDataByOriginalId',
      getPatient: 'patients/getDataById'
    }),
    title() {
      return this.dataType === 'estimate'
        ? '見積書の印刷'
        : this.dataType === 'receipt'
        ? '領収書の印刷'
        : this.dataType === 'prescription'
        ? '処方箋の印刷'
        : this.dataType === 'medicalPayment'
        ? '診療明細の印刷'
        : this.dataType === 'examination'
        ? '検査の印刷'
        : this.dataType === 'vaccinationCertificate'
        ? '予防接種証明書の印刷'
        : ''
    },
    diffFlg() {
      if (this.dataType !== 'receipt') return false
      return !_.isEqual(this.initialPrintOptions, this.printOptions)
    }
  },

  created() {
    // 使用/表示する印刷オプションを設定
    const defaultPrintOptionKeys =
      this.printOptionKeysByType[this.dataType] ?? []
    this.usingPrintOptionKeys = defaultPrintOptionKeys.filter(
      v => !this.removingPrintOptionKeys.includes(v)
    )
    const showInvoiceNumber = this.isUsedPrintOption('showInvoiceNumber')
      ? Boolean(this.clinic.invoiceNumber)
      : false
    if (showInvoiceNumber) this.showFormItemInvoiceNumber = true

    // 入力に使用する値を設定
    this.printOptions = {
      paperSize: this.printSetting.defaultPaperSize,
      receiptDate: new Date(),
      ownerName: this.owner
        ? `${this.owner.lastName} ${this.owner.firstName}`
        : '',
      proviso: '診療代',
      showClinicName: Boolean(this.printSetting.printClinicNameFlg),
      showClinicTel: this.toShowClinicItem(
        this.printSetting,
        'printClinicTelFlg'
      ),
      showClinicEmail: this.toShowClinicItem(
        this.printSetting,
        'printClinicEmailFlg'
      ),
      showClinicAddress: this.toShowClinicItem(
        this.printSetting,
        'printClinicAddressFlg'
      ),
      showInvoiceNumber,
      showBarcode: this.toShowBarcode(this.printSetting),
      showNextReservation: false,
      printCopy: false
    }
    if (this.dataType === 'receipt') {
      this.initialPrintOptions = _.cloneDeep(this.printOptions)
    }
  },

  methods: {
    isUsedPrintOption(key) {
      return this.usingPrintOptionKeys.includes(key)
    },
    toShowClinicItem(printSetting, itemName) {
      if (this.dataType !== 'medicalPayment') return false
      return Boolean(printSetting[itemName])
    },
    toShowBarcode(printSetting) {
      return this.dataType === 'medicalPayment'
        ? String(this.data.burdenAmount).length > 6
          ? false
          : Boolean(printSetting.barcodeFlg)
        : false
    },
    filterPrintOptions(keys) {
      let filteredPrintOptions = {}
      keys.forEach(key => {
        filteredPrintOptions[key] = this.printOptions[key]
      })
      return filteredPrintOptions
    },
    inputShowBarcode(show) {
      this.printOptions.showBarcode = show
      if (show && String(this.data.burdenAmount).length > 6) {
        this.popupCloseAction = () => {
          this.printOptions.showBarcode = false
        }
        this.popup = {
          type: 'alert',
          title: '注意',
          message: '自己負担額が7桁以上の場合バーコードは利用できません。',
          buttons: ['閉じる'],
          layerNumber: 2
        }
        this.popupFlg = true
      }
    },
    closePopup() {
      if (this.popupCloseAction) {
        this.popupCloseAction()
        this.$nextTick(() => (this.popupCloseAction = null))
      }
      this.popupFlg = false
    },
    cancel() {
      if (this.diffFlg) {
        this.popup = {
          type: 'alert',
          title: '確認',
          message: '閉じようとしています。よろしいですか？',
          buttons: ['キャンセル', '閉じる'],
          layerNumber: 2
        }
        this.popupFlg = true
      } else {
        this.$emit('close')
      }
    },
    makePdf() {
      const prefectureName =
        this.getMasterDatum('prefectures', this.clinic.prefectureId)?.name || ''
      const clinic = { ...this.clinic, prefectureName }
      const { image: clinicImage, stamp: clinicStamp } = this.printSetting
      if (this.dataType === 'estimate') {
        printEstimate({
          printOptions: this.filterPrintOptions(this.usingPrintOptionKeys),
          clinic,
          clinicImage,
          owner: this.owner,
          patient: this.patient,
          estimate: this.data
        })
      } else if (this.dataType === 'receipt') {
        printReceipt({
          printOptions: this.filterPrintOptions(this.usingPrintOptionKeys),
          clinic,
          clinicImage,
          payment: this.data
        })
      } else if (this.dataType === 'prescription') {
        const prescriptionItems = this.data.prescriptionItems.map(v => {
          const medicine = v.medicineId
            ? this.getMedicine(v.medicineId)
            : this.getMedicineByOriginalId(v.medicineOriginalId)
          return { medicineName: medicine.name, prescription: v.prescription }
        })
        printPrescription({
          printOptions: this.filterPrintOptions(this.usingPrintOptionKeys),
          clinic,
          clinicImage,
          owner: this.owner,
          patient: this.patient,
          treatmentDate: this.data.treatmentDate,
          estimateDate: this.data.estimateDate,
          prescriptionItems
        })
      } else if (this.dataType === 'medicalPayment') {
        let nextReservationInfo = null
        if (this.printOptions.showNextReservation) {
          const now = moment()
          const currentDay = now.format('YYYYMMDD')
          const currentTime = now.format('HHmm')
          const medicalRecord = this.getMedicalRecordByOriginalId(
            this.data.medicalRecordOriginalId
          )
          const ownerFutureReservations = this.reservations.filter(
            reservation =>
              reservation.id !== medicalRecord.reservationId &&
              reservation.cancelFlg === 0 &&
              reservation.delFlg === 0 &&
              reservation.ownerId === this.owner.id &&
              (reservation.date > currentDay ||
                (reservation.date === currentDay &&
                  reservation.startTime > currentTime))
          )
          if (ownerFutureReservations.length > 0) {
            const oneNextReservation = ownerFutureReservations.reduce((a, b) =>
              a.date + a.startTime <= b.date + b.startTime ? a : b
            )
            const nextReservations = ownerFutureReservations.filter(
              v =>
                v.date === oneNextReservation.date &&
                v.startTime === oneNextReservation.startTime
            )
            const patientNames = nextReservations.reduce(
              (patientNames, reservation) => {
                const patient = this.getPatient(reservation.patientId)
                if (patient && !patientNames.includes(patient.name)) {
                  patientNames +=
                    patientNames === ''
                      ? `${patient.name}様\n`
                      : `${patient.name}様\n`
                }
                return patientNames
              },
              ''
            )
            nextReservationInfo = {
              text:
                '次回の予約：' +
                moment(
                  oneNextReservation.date + oneNextReservation.startTime,
                  'YYYYMMDDHHmm'
                ).format('Y年M月D日（dd）H時mm分~'),
              patientNames
            }
          } else {
            nextReservationInfo = {}
          }
        }
        const {
          showNextReservation,
          ...printOptions
        } = this.filterPrintOptions(this.usingPrintOptionKeys)
        printMedicalPayment({
          printOptions,
          clinic,
          clinicImage,
          barcodePrefix: this.printSetting.barcodePrefix,
          nextReservationInfo,
          printData: [
            {
              owner: this.owner,
              patient: this.patient,
              medicalPayment: this.data
            }
          ]
        })
      } else if (this.dataType === 'examination') {
        printExamination({
          printOptions: this.filterPrintOptions(this.usingPrintOptionKeys),
          clinic,
          clinicImage,
          owner: this.owner,
          patient: this.patient,
          examinationItems: makeFilteredResultGroupData(
            this.data.examinationItems
          ),
          examinationDate: moment(this.data.examinationItems[0][0].date).format(
            'Y年M月D日'
          )
        })
      } else if (this.dataType === 'vaccinationCertificate') {
        const ownerPrefectureName =
          this.getMasterDatum('prefectures', this.owner.prefectureId)?.name ||
          ''
        printVaccinationCertificate({
          printOptions: this.filterPrintOptions(this.usingPrintOptionKeys),
          clinic,
          clinicImage,
          clinicStamp,
          owner: { ...this.owner, prefectureName: ownerPrefectureName },
          patient: {
            ...this.patient,
            displayBirthday: makeBirthdayJp(this.patient),
            age: calcAge(this.patient)
          },
          vaccineData: this.data
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.print-options-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    min-width: 460px;
    font-size: 15px;
    text-align: left;
    > .title {
      font-size: 16px;
      font-weight: bold;
    }
    > .form {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      > .form-item {
        display: flex;
        height: 33px;
        > .label {
          width: 180px;
          display: flex;
          align-items: center;
        }
        > .input-field {
          flex: 1;
          display: flex;
          align-items: center;
          > .radio-buttons {
            display: flex;
          }
        }
        ::v-deep {
          .v-date-picker {
            text-align: right;
            padding-right: 12px;
            width: 140px;
            height: 33px;
            border-radius: 5px;
            border: solid 2px #{$light-grey};
            text-indent: 5px;
            font-size: 13px;
            cursor: pointer;
            background-image: url('../../assets/images/calendar.png');
            background-size: 18px 18px;
            background-repeat: no-repeat;
            background-position: 5px center;
          }
        }
      }
    }
  }
}
</style>
