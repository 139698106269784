<template>
  <focus-trap :clickDeactivates="false" :escapeDeactivates="false">
    <div
      class="measurement-result-popup"
      :tabindex="`-${layerNumber}`"
      @keyup.esc.stop="handleClose"
    >
      <popup
        :buttons="buttons"
        :content-style="{}"
        :disabled-flg="disabledFlg"
        :layer-number="layerNumber"
        @cancel="handleClose"
        @click-close-mark="handleClose"
        @close="handleClose"
        @decision="handleDecide"
      >
        <template v-slot:content>
          <div class="content">
            <div class="header">
              <div class="title">検査結果詳細（機器）</div>
              <base-button-small-red
                v-if="!lookOnlyFlg && canDelete"
                @click="openDeletePopup"
                >削除</base-button-small-red
              >
            </div>
            <div class="detail">
              <div class="measurement-result-table">
                <div class="subgrid-container">
                  <div class="grid-item label">検査日時</div>
                  <div class="grid-item">{{ formattedDateTime }}</div>
                </div>
                <div class="subgrid-container">
                  <div class="grid-item label">検査名</div>
                  <div class="grid-item">{{ categoryName }}</div>
                </div>
                <div class="subgrid-container">
                  <div class="grid-item label">検査機器</div>
                  <div class="grid-item">{{ machineName }}</div>
                </div>
              </div>
              <div
                class="measurement-result-items-table"
                :style="{ '--column-count': columnCount }"
              >
                <div class="subgrid-container">
                  <div class="grid-item label">検査項目</div>
                  <div class="grid-item label">結果</div>
                  <div class="grid-item label">単位</div>
                  <div v-if="showReferenceRange" class="grid-item label">
                    <span>基準値</span>
                  </div>
                </div>
                <div
                  v-for="item in displayMeasurementResultItems"
                  class="subgrid-container"
                  :key="item.id"
                  :style="{ '--column-count': columnCount }"
                >
                  <div class="grid-item">{{ item.name }}</div>
                  <div class="grid-item">{{ item.result }}</div>
                  <div class="grid-item">{{ item.unit }}</div>
                  <div v-if="showReferenceRange" class="grid-item">
                    <span>{{ item.range }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </popup>
      <announce-popup
        v-if="deletePopup.isOpened"
        data-test="popup delete"
        v-bind="deletePopup"
        :disabled="waitFlg"
        @cancel="deletePopup.cancel"
        @decision="deletePopup.decide"
        >{{ deletePopup.message }}</announce-popup
      >
      <announce-popup
        v-if="resultPopup.isOpened"
        data-test="popup result"
        v-bind="resultPopup"
        @close="resultPopup.close"
        >{{ resultPopup.message }}</announce-popup
      >
    </div>
  </focus-trap>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import Popup from '@/components/popups/Popup'
import { findMasterLaboratoryItemRange } from '@/utils/measurement_utils'
import moment from 'moment'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MeasurementResultPopup',

  components: {
    AnnouncePopup,
    BaseButtonSmallRed,
    FocusTrap,
    Popup
  },

  props: {
    buttons: { type: Array, default: () => ['閉じる'] },
    canDelete: { type: Boolean, default: false },
    disabledFlg: { type: Boolean, default: false },
    layerNumber: { type: Number, default: 1 },
    measurementResultId: { type: Number, default: 0 },
    showReferenceRange: { type: Boolean, default: true }
  },

  data() {
    return {
      deletePopup: {
        buttons: ['削除しない', '削除する'],
        cancel: this.closeDeletePopup,
        decide: this.decideDelete,
        isOpened: false,
        layerNumber: this.layerNumber + 1,
        message: '削除してもよろしいですか？',
        title: '確認',
        type: 'alert'
      },
      resultPopup: {
        buttons: ['閉じる'],
        close: this.closeResultPopup,
        isOpened: false,
        layerNumber: this.layerNumber + 2,
        message: '',
        title: '',
        type: ''
      },
      shouldCloseSelf: false,
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      getMasterLaboratoryCategory:
        'master/getMasterLaboratoryCategoryIndexedByCode',
      getMasterLaboratoryItem: 'master/getMasterLaboratoryItemIndexedByCode',
      getMasterLaboratoryItemUnit:
        'master/getMasterLaboratoryItemUnitIndexedByCode',
      getMasterLaboratoryMachine:
        'master/getMasterLaboratoryMachineIndexedByCode',
      getMeasurementResult: 'measurementResults/getDataById',
      getMeasurementResultItems:
        'measurementResultItems/getDataByMeasurementResultId',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    categoryName() {
      const masterLaboratoryCategory = this.getMasterLaboratoryCategory(
        this.masterLaboratoryMachine.masterLaboratoryCategoryCode
      )
      return masterLaboratoryCategory.name
    },
    columnCount() {
      return this.showReferenceRange ? 4 : 3
    },
    displayMeasurementResultItems() {
      return this.measurementResultItems.map(v => {
        const masterLaboratoryItem = this.getMasterLaboratoryItem(
          v.masterLaboratoryItemCode
        )
        const masterLaboratoryItemUnit = this.getMasterLaboratoryItemUnit(
          masterLaboratoryItem.masterLaboratoryItemUnitCode
        )
        return {
          id: v.id,
          name: masterLaboratoryItem.enName,
          result: this.toResult(v),
          unit: masterLaboratoryItemUnit.name,
          range: this.toRangeText(masterLaboratoryItem)
        }
      })
    },
    formattedDate() {
      return moment(this.measurementResult.date, 'YYYYMMDD').format(
        'Y年M月D日（dd）'
      )
    },
    formattedDateTime() {
      return `${this.formattedDate}${this.formattedTime}`
    },
    formattedTime() {
      return this.measurementResult.time
        ? moment(this.measurementResult.time, 'HHmm').format('H時m分')
        : ''
    },
    machineName() {
      return this.masterLaboratoryMachine.name
    },
    masterLaboratoryMachine() {
      return this.getMasterLaboratoryMachine(
        this.measurementResult.masterLaboratoryMachineCode
      )
    },
    measurementResult() {
      const measurementResultItem = this.getMeasurementResult(
        this.measurementResultId
      )
      return measurementResultItem ?? {}
    },
    measurementResultItems() {
      const measurementResultItems = this.getMeasurementResultItems(
        this.measurementResultId
      )
      return measurementResultItems ?? []
    }
  },

  methods: {
    closeDeletePopup() {
      this.deletePopup.isOpened = false
    },
    closeResultPopup() {
      this.resultPopup.isOpened = false
      if (this.shouldCloseSelf) {
        this.$emit('close')
      }
    },
    async decideDelete() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'measurementResults/delete',
        this.measurementResultId
      )
      if (result === 'success') {
        this.resultPopup = {
          ...this.resultPopup,
          isOpened: true,
          message: '削除しました',
          title: '完了',
          type: 'success'
        }
        this.shouldCloseSelf = true
      } else {
        this.resultPopup = {
          ...this.resultPopup,
          isOpened: true,
          message: '削除に失敗しました',
          title: '失敗',
          type: 'failure'
        }
        if (result === 'no data in clinic') {
          this.shouldCloseSelf = true
        }
      }
      this.waitFlg = false
    },
    handleClose() {
      this.$emit('close')
    },
    handleDecide() {
      this.$emit('decide')
    },
    openDeletePopup() {
      this.deletePopup.isOpened = true
    },
    toRangeText(masterLaboratoryItem) {
      if (!this.showReferenceRange || !masterLaboratoryItem) return ''
      const rangeRecord = findMasterLaboratoryItemRange({
        masterLaboratoryItemCode: masterLaboratoryItem.code,
        measurementResult: this.measurementResult
      })
      return rangeRecord
        ? `${rangeRecord.lowerLimit} – ${rangeRecord.upperLimit}`
        : ''
    },
    toResult(measurementResultItem) {
      const { equalSign, value } = measurementResultItem
      return `${equalSign.replace('=', '')} ${value}`
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin table-cell {
  padding: 4px;
  border-right: 1px solid #{$light-grey};
  border-bottom: 1px solid #{$light-grey};
  font-size: 13px;
  overflow-wrap: anywhere;
  line-break: strict;
  &.label {
    background-color: #{$very-light-shade-orange};
  }
}

.measurement-result-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    min-width: 590px;
    height: 470px;
    > .header {
      display: flex;
      justify-content: space-between;
      height: 33px;
      > .title {
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        color: #{$greyish-brown};
      }
    }
    > .detail {
      margin-top: 20px;
      max-height: 415px;
      overflow-y: auto;
      > .measurement-result-table {
        display: grid;
        grid-template-columns: 120px auto;
        border-top: 1px solid #{$light-grey};
        border-left: 1px solid #{$light-grey};
        > .subgrid-container {
          grid-column: span 2;
          display: grid;
          grid-template-columns: subgrid;
          > .grid-item {
            @include table-cell;
            text-align: left;
          }
        }
      }
      > .measurement-result-items-table {
        margin-top: 5px;
        display: grid;
        grid-template-columns: repeat(var(--column-count), auto);
        border-top: 1px solid #{$light-grey};
        border-left: 1px solid #{$light-grey};
        > .subgrid-container {
          grid-column: span var(--column-count);
          display: grid;
          grid-template-columns: subgrid;
          > .grid-item {
            @include table-cell;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
