<template>
  <div class="accordion-list-row">
    <div
      class="parent"
      :title="parent.name"
      :style="parentStyles"
      @click="clickRow"
    >
      <div class="blank-area" :style="blankAreaStyles" />
      <div class="text">
        <div class="image-allow" v-if="parent.children">
          <img
            src="@/assets/images/allow3.png"
            class="bottom-allow"
            v-show="panelShowFlg"
          />
          <img
            src="@/assets/images/allow4.png"
            class="right-allow"
            v-show="!panelShowFlg"
          />
        </div>
        <div
          class="name"
          :class="{ orange: textOrangeFlg }"
          :style="nameStyles"
        >
          {{ parent.name }}
        </div>
        <div class="label" v-if="parent.label">{{ parent.label }}</div>
      </div>
    </div>
    <accordion-list-row
      v-show="panelShowFlg"
      v-for="child in parent.children"
      :key="child.id"
      :parent="child"
      :parentPanelShowFlg="panelShowFlg"
      @click-row="val => $emit('click-row', val)"
    />
  </div>
</template>

<script>
import AccordionListRow from '@/components/parts/molecules/AccordionListRow'

export default {
  name: 'AccordionListRow',

  components: {
    AccordionListRow
  },

  props: {
    parent: { type: Object },
    textOrangeFlg: { type: Boolean, default: false },
    parentPanelShowFlg: { default: false }
  },

  data() {
    return {
      panelShowFlg: false
    }
  },

  computed: {
    parentStyles() {
      return this.parent.layerNumber === 1
        ? {}
        : this.parent.layerNumber === 2
        ? { backgroundColor: '#f7f7f7' }
        : this.parent.layerNumber === 3
        ? { backgroundColor: '#eaeaea' }
        : this.parent.layerNumber === 4
        ? { backgroundColor: '#cecece' }
        : { backgroundColor: '#bebebe' }
    },
    blankAreaStyles() {
      return this.parent.layerNumber === 1
        ? { minWidth: '0px' }
        : this.parent.layerNumber === 2
        ? { minWidth: '20px' }
        : this.parent.layerNumber === 3
        ? { minWidth: '40px' }
        : this.parent.layerNumber === 4
        ? { minWidth: '60px' }
        : { minWidth: '80px' }
    },
    nameStyles() {
      if (this.parent.layerNumber === 1 && !this.parent.children) {
        return { fontWeight: 'bold', marginLeft: '0px' }
      } else if (this.parent.layerNumber === 1) {
        return { fontWeight: 'bold' }
      } else if (!this.parent.children) {
        return { marginLeft: '0px' }
      } else {
        return {}
      }
    }
  },

  watch: {
    parentPanelShowFlg: function(newVal) {
      if (newVal === false) this.panelShowFlg = false
    }
  },

  methods: {
    clickRow() {
      if (this.parent.children) {
        this.panelShowFlg = !this.panelShowFlg
      } else {
        this.$emit('click-row', this.parent)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion-list-row {
  width: 100%;
  > .parent {
    display: flex;
    height: 40px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    > .text {
      flex: 1;
      display: flex;
      box-sizing: border-box;
      padding-left: 20px;
      line-height: 40px;
      text-align: left;
      font-size: 12px;
      border-bottom: 1px solid #{$plus_c2_gray};
      > .image-allow {
        width: 16px;
        > .bottom-allow {
          margin-top: 17px;
          width: 15px;
          height: 9px;
        }
        > .right-allow {
          margin-top: 12px;
          width: 9px;
          height: 15px;
        }
      }
      > .name {
        flex: 1;
        width: 0;
        margin-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.orange {
          color: #{$pumpkin};
        }
      }
      > .label {
        align-self: flex-end;
        justify-self: flex-end;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 18px;
        line-height: 18px;
        border-radius: 20px;
        font-size: 12px;
        margin: auto 10px auto auto;
        box-sizing: border-box;
        min-width: 60px;
        color: #{$gray_7};
        border: solid 1px #{$gray_7};
        padding: 5px;
      }
    }
  }
}
</style>
