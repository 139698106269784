<template>
  <div class="accordion-list" :style="styles">
    <accordion-list-row
      v-for="(parent, index) in data"
      :key="keyIndexFlg ? index : parent.id"
      :parent="parent"
      :textOrangeFlg="textOrangeFlg"
      :ref="`row_${index}`"
      @click-row="val => $emit('click-row', val)"
    />
  </div>
</template>

<script>
import AccordionListRow from '@/components/parts/molecules/AccordionListRow'

export default {
  name: 'AccordionList',

  components: {
    AccordionListRow
  },

  props: {
    data: { type: Array },
    styles: { type: Object },
    keyIndexFlg: { type: Boolean, default: false },
    textOrangeFlg: { type: Boolean, default: false }
  },

  methods: {
    closePanel() {
      this.data.forEach((_, i) => {
        this.$refs[`row_${i}`][0].panelShowFlg = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion-list {
  box-sizing: border-box;
  width: 590px;
  height: 375px;
  border: 1px solid #{$light-grey};
  overflow: auto;
  padding-bottom: 20px;
}
</style>
