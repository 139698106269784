import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'measurementResults'
const keys = ['patientId']

const state = {
  [dataName]: [],
  dataIndexedById: {},
  updatedAt: '20101010101010'
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(dataName, ['getDataById', 'getUpdatedAt'], {
    filterDel: true,
    getDataBy: keys
  })
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async getUnlinkedData({ commit }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get('/measurement-results/unlinked-data')
      if (res.status === 200) {
        const stores = ['measurementResultItems', 'measurementResults']
        stores.forEach(store => {
          commit(`${store}/updateAfter`, res.data[store], { root: true })
        })
        return 'success'
      }
    } catch {
      return 'failure'
    }
  },
  async updatePatientId({ commit }, { id, newPatientId }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/measurement-results/patient-id', {
        id,
        newPatientId
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.measurementResult])
        return 'success'
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.message === 'no data in clinic') {
          commit('setData', error.response.data.extra.data)
          return 'no data in clinic'
        } else if (error.response.data.message === 'no data') {
          return `no ${error.response.data.extra}`
        } else {
          return error.response.data.message
        }
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/measurement-results', {
        data: { id }
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.measurementResult])
        commit(
          'measurementResultItems/updateAfter',
          res.data.measurementResultItems,
          { root: true }
        )
        return 'success'
      }
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    indexedById: true,
    setDataBy: keys
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
