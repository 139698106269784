<template>
  <div class="main-header">
    <img
      src="@/assets/images/header_logo.png"
      class="header-logo-img"
      @click="pushToMainOrLogin"
      width="199px"
      height="34px"
      alt="header-logo"
    />
    <div v-if="loginedFlg" class="right-div">
      <base-header-notification
        :unreadCounts="unreadCounts"
        @click="pushToNotifications"
        class="notification"
      />
      <base-header-reservation-request-count
        :unappliedCounts="unappliedCounts"
        @click="pushToReservationRequests"
        class="reservation-request-count"
      />
      <div class="staff">
        <header-login-staff
          :headerStaff="headerStaff"
          @popup="popupFlg = true"
        />
        <arrow-box
          v-if="popupFlg"
          @popup="popupFlg = true"
          :id="headerStaff.id"
          class="arrow-box"
        />
      </div>
      <a :href="helpLink" target="_blank" rel="noopener noreferrer">
        <base-header-help />
      </a>
      <div class="cover" v-if="popupFlg" @click="popupFlg = false"></div>
    </div>
  </div>
</template>

<script>
import BaseHeaderNotification from '@/components/parts/atoms/BaseHeaderNotification'
import BaseHeaderReservationRequestCount from '@/components/parts/atoms/BaseHeaderReservationRequestCount'
import HeaderLoginStaff from '@/components/parts/molecules/HeaderLoginStaff'
import BaseHeaderHelp from '@/components/parts/atoms/BaseHeaderHelp'
import { mapGetters } from 'vuex'
import ArrowBox from '@/components/parts/atoms/ArrowBox'
import HelpLink from '@/components/mixins/HelpLink'

export default {
  name: 'MainHeader',

  components: {
    BaseHeaderNotification,
    HeaderLoginStaff,
    BaseHeaderHelp,
    BaseHeaderReservationRequestCount,
    ArrowBox
  },

  mixins: [HelpLink],

  props: {
    loginedFlg: { type: Boolean, default: true }
  },

  data() {
    return {
      popupFlg: false
    }
  },

  computed: {
    ...mapGetters({
      noticesNoReadCount: 'notices/getCount',
      receivedMailsNoReadCount: 'receivedMails/getCount',
      reservationRequestsCount: 'reservationRequests/getCount',
      cancelReservationsNoReadCount: 'reservations/getCount'
    }),
    unreadCounts() {
      const unreadCounts =
        this.noticesNoReadCount +
        this.receivedMailsNoReadCount +
        this.reservationRequestsCount +
        this.cancelReservationsNoReadCount
      return unreadCounts < 100 ? unreadCounts : '99+'
    },
    unappliedCounts() {
      return this.reservationRequestsCount < 100
        ? this.reservationRequestsCount
        : '99+'
    },
    headerStaff: function() {
      const staffId = this.$store.getters['auth/loginStaffId']
      const staff = this.$store.getters['staffs/getDataById'](staffId)
      return {
        id: staffId,
        name: staff.lastName + ' ' + staff.firstName,
        image: staff.image
      }
    },
    helpLink() {
      return this.mixinHelpLink()
    }
  },

  methods: {
    pushToNotifications() {
      this.$router.push({ path: '/main/notifications/notices' }, () => {})
    },
    pushToReservationRequests() {
      this.$router.push(
        { path: '/main/notifications/reservation-requests' },
        () => {}
      )
    },
    pushToMainOrLogin() {
      this.$route.path.includes('password-reset')
        ? this.$router.push({ path: '/login' }, () => {})
        : this.$router.push({ path: '/main' }, () => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.main-header {
  width: 100%;
  min-width: 768px;
  height: 64px;
  box-sizing: border-box;
  border-bottom: solid 2px #{$pumpkin};
  > .header-logo-img {
    float: left;
    margin-top: 15px;
    margin-left: 15px;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  > .right-div {
    float: right;
    display: flex;
    margin-right: 20px;
    height: 64px;
    > .notification {
      margin-bottom: 20px;
    }
    > .reservation-request-count {
      margin-bottom: 20px;
      margin-right: 20px;
      margin-left: 20px;
    }
    > .staff {
      margin-right: 20px;
      position: relative;
      > .arrow-box {
        position: absolute;
        top: 60px;
      }
    }
    > .cover {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      opacity: 1;
      z-index: 1000;
    }
  }
}
</style>
