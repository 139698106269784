import { getAxiosObject } from '@/utils/library'
import {
  commonGetters,
  commonActions,
  commonMutations
} from '@/store/store_utils'

const dataName = 'owners'

const state = {
  owners: [],
  dataIndexedById: {},
  updatedAt: '20101010101010',
  acquiredPatientIds: new Set()
}

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataIncludeDel', 'getDataById', 'getUpdatedAt'],
    { filterDel: true }
  ),
  getAcquiredPatientIds(state) {
    return state.acquiredPatientIds
  }
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  async create({ commit }, { owner, reservation, mailFlgs }) {
    try {
      const axiosObject = getAxiosObject()
      const res = reservation
        ? await axiosObject.post('/owners', {
            owner,
            reservation,
            mailFlgs
          })
        : await axiosObject.post('/owners', {
            owner
          })
      if (res.status === 200) {
        commit('updateAfter', [res.data.owner])
        if (res.data.reservations) {
          commit('reservations/updateAfter', res.data.reservations, {
            root: true
          })
        }
        if (res.data.sendMail) {
          commit('sendMails/updateAfter', [res.data.sendMail], {
            root: true
          })
        }
        return { result: true, ownerId: res.data.owner.id }
      }
    } catch (error) {
      if (error.response?.data?.message === 'already used') {
        if (error.response?.data?.extra.field === 'showId') {
          commit('updateAfter', [error.response.data.extra.owner])
        }
        return `used ${error.response.data.extra.field}`
      } else if (error.response?.data?.message === 'no data') {
        return `no ${error.response.data.extra}`
      } else if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('reservations/setData', error.response.data.extra.data, {
            root: true
          })
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async update({ commit }, { owner, updatePatients }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.put('/owners', {
        owner,
        updatePatients
      })
      if (res.status === 200) {
        commit('updateAfter', [res.data.owner])
        if (res.data.patients.length !== 0) {
          commit('patients/updateAfter', res.data.patients, {
            root: true
          })
        }
        return true
      }
    } catch (error) {
      if (error.response?.data?.message === 'already used') {
        if (error.response?.data?.extra.field === 'showId') {
          commit('updateAfter', [error.response.data.extra.owner])
        }
        return `used ${error.response.data.extra.field}`
      } else if (error.response?.data?.message === 'no data') {
        return `no ${error.response.data.extra}`
      } else if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async delete({ commit, rootGetters }, id) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.delete('/owners', {
        data: { id }
      })
      commit('updateAfter', [res.data.owner])
      commit('patients/updateAfter', res.data.patients, { root: true })
      const karteFlg = rootGetters['auth/karteFlg']
      if (karteFlg) {
        const stores = [
          'anicomCIdChecks',
          'anicomPatients',
          'antibodies',
          'antibodyTypes',
          'estimateTreatmentItems',
          'estimates',
          'examinationResults',
          'hospitalizations',
          'ipetPatients',
          'measurementResultItems',
          'measurementResults',
          'medicalContentImageTexts',
          'medicalContentImages',
          'medicalContents',
          'medicalPayments',
          'medicalRecords',
          'medicalTreatmentItems',
          'patientVaccinePeriods',
          'patientVaccines',
          'payments',
          'problems',
          'reservationRequests',
          'reservations',
          'uploadImages',
          'vaccineReminders'
        ]
        stores.forEach(store => {
          const storeData = res.data[store]
          if (storeData.length > 0) {
            commit(`${store}/updateAfter`, storeData, { root: true })
          }
        })
      } else {
        if (res.data.anicomPatients.length > 0) {
          commit('anicomPatients/updateAfter', res.data.anicomPatients, {
            root: true
          })
        }
        if (res.data.ipetPatients.length > 0) {
          commit('ipetPatients/updateAfter', res.data.ipetPatients, {
            root: true
          })
        }
        if (res.data.reservations.length > 0) {
          commit('reservations/updateAfter', res.data.reservations, {
            root: true
          })
        }
        if (res.data.reservationRequests.length > 0) {
          commit(
            'reservationRequests/updateAfter',
            res.data.reservationRequests,
            { root: true }
          )
        }
      }
      return true
    } catch (error) {
      if (error.response?.data?.message === 'currently used') {
        return `used in ${error.response.data.extra}`
      } else if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async get({ commit, rootGetters }, { id, notAcquiredPatientIds }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get(`/${dataName}`, {
        params: { id, notAcquiredPatientIds }
      })
      if (res.status === 200) {
        commit('insertPatientIds', notAcquiredPatientIds)
        if (res.data.patientsImages.length > 0) {
          commit(
            'patients/setImages',
            { patientsImages: res.data.patientsImages, ownerId: id },
            { root: true }
          )
        }
        if (res.data.reservations.length > 0) {
          commit('reservations/updateAfter', res.data.reservations, {
            root: true
          })
        }
        const karteFlg = rootGetters['auth/karteFlg']
        if (karteFlg) {
          const vaccinationStores = [
            'patientVaccines',
            'patientVaccinePeriods',
            'antibodies',
            'antibodyTypes'
          ]
          vaccinationStores.forEach(store => {
            const storeData = res.data[store]
            if (storeData.length > 0)
              commit(`${store}/updateAfter`, storeData, { root: true })
          })
        }
        return true
      }
    } catch (error) {
      if (error.response?.data?.message) {
        return error.response.data.message === 'no data'
          ? `no ${error.response.data.extra}`
          : error.response.data.message
      } else {
        return 'server error'
      }
    }
  },
  async getIsPasswordConfigured({ commit }, ownerId) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get('/owners/password', {
        params: { id: ownerId }
      })
      return {
        result: true,
        isPasswordConfigured: res.data.isPasswordConfigured
      }
    } catch (error) {
      return 'server error'
    }
  },
  async createAndSendPassword({ commit }, ownerId) {
    try {
      const axiosObject = getAxiosObject()
      await axiosObject.put('/owners/password', {
        id: ownerId
      })
      return true
    } catch (error) {
      if (error.response?.data?.message) {
        if (error.response.data.extra?.data) {
          commit('setData', error.response.data.extra.data)
        }
        return error.response.data.message
      } else {
        return 'server error'
      }
    }
  }
}

const mutations = {
  ...commonMutations(dataName, ['setData', 'setUpdatedAt', 'updateAfter'], {
    indexedById: true,
    csvColumns: ['ownerTagIds']
  }),
  insertPatientIds(state, patientIds) {
    patientIds.forEach(v => state.acquiredPatientIds.add(v))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
