const state = {
  contactTypes: [],
  jobs: [],
  jobsIndexedById: {},
  noticeCategories: [],
  noticeCategoriesIndexedById: {},
  masterLaboratoryCategories: [],
  masterLaboratoryCategoriesIndexedByCode: {},
  masterLaboratoryItemRanges: [],
  masterLaboratoryItemSpecies: [],
  masterLaboratoryItemSpecimenTypes: [],
  masterLaboratoryItemSpecimenTypesIndexedByValue: {},
  masterLaboratoryItemUnits: [],
  masterLaboratoryItemUnitsIndexedByCode: {},
  masterLaboratoryItems: [],
  masterLaboratoryItemsIndexedByCode: {},
  masterLaboratoryMachines: [],
  masterLaboratoryMachinesIndexedByCode: {},
  patientSexes: [],
  prefectures: [],
  prefecturesIndexedById: {},
  publicHolidays: [],
  reservationStatuses: [],
  reservationStatusesIndexedById: {},
  shiftTypes: [],
  shiftTypesIndexedById: {},
  unitTypes: [],
  unitTypesIndexedById: {},
  vaccines: [],
  vaccinesIndexedById: {},
  vaccineSubNames: [],
  vaccineSubNamesIndexedById: {},
  schemaImageClasses: [],
  schemaImageClassesIndexedById: {}
}

const getters = {
  getContactTypes(state) {
    return state.contactTypes
  },
  getJobs(state) {
    return state.jobs
  },
  getMasterLaboratoryCategories(state) {
    return state.masterLaboratoryCategories
  },
  getMasterLaboratoryCategoryIndexedByCode: state => code => {
    return state.masterLaboratoryCategoriesIndexedByCode[code]
  },
  getMasterLaboratoryItemRanges(state) {
    return state.masterLaboratoryItemRanges
  },
  getMasterLaboratoryItemSpecies(state) {
    return state.masterLaboratoryItemSpecies
  },
  getMasterLaboratoryItemSpecimenTypes(state) {
    return state.masterLaboratoryItemSpecimenTypes
  },
  getMasterLaboratoryItemSpecimenTypeIndexedByValue: state => value => {
    return state.masterLaboratoryItemSpecimenTypesIndexedByValue[value]
  },
  getMasterLaboratoryItemUnits(state) {
    return state.masterLaboratoryItemUnits
  },
  getMasterLaboratoryItemUnitIndexedByCode: state => code => {
    return state.masterLaboratoryItemUnitsIndexedByCode[code]
  },
  getMasterLaboratoryItems(state) {
    return state.masterLaboratoryItems
  },
  getMasterLaboratoryItemIndexedByCode: state => code => {
    return state.masterLaboratoryItemsIndexedByCode[code]
  },
  getMasterLaboratoryMachines(state) {
    return state.masterLaboratoryMachines
  },
  getMasterLaboratoryMachineIndexedByCode: state => code => {
    return state.masterLaboratoryMachinesIndexedByCode[code]
  },
  getPrefectures(state) {
    return state.prefectures
  },
  getPrefectureIndexedById: state => prefectureId => {
    return state.prefecturesIndexedById[prefectureId]
  },
  getPublicHolidays(state) {
    return state.publicHolidays
  },
  getShiftTypes(state) {
    return state.shiftTypes
  },
  getData(state) {
    return dataName => state[dataName]
  },
  selectPatientSexesZero(state) {
    return [{ id: 0, name: '' }].concat(state.patientSexes)
  },
  selectPrefecturesZero(state) {
    return [{ id: 0, name: '' }].concat(state.prefectures)
  },
  getReservationStatus(state) {
    return state.reservationStatuses
  },
  selectVaccinesZero(state) {
    const vaccines = state.vaccines.map(v => {
      return { id: v.id, name: v.name }
    })
    return [{ id: 0, name: '' }].concat(vaccines)
  },
  getDataById(state) {
    return (dataName, id) => state[`${dataName}IndexedById`][id]
  },
  selectSchemaImageClassesZero(state) {
    return [{ id: 0, name: '全て' }].concat(state.schemaImageClasses)
  }
}

const actions = {
  setData({ commit }, obj) {
    commit('setData', obj)
  },
  setKarteData({ commit }, obj) {
    commit('setKarteData', obj)
  },
  deleteMasterData({ commit }) {
    commit('deleteData')
  }
}

const setDataIndexedById = (dataName, state) => {
  state[`${dataName}IndexedById`] = {}
  state[dataName].forEach(v => {
    state[`${dataName}IndexedById`][v.id] = v
  })
}

const setDataIndexedByCode = (dataName, state) => {
  state[`${dataName}IndexedByCode`] = {}
  state[dataName].forEach(v => {
    state[`${dataName}IndexedByCode`][v.code] = v
  })
}

const setDataIndexByValue = (dataName, state) => {
  state[`${dataName}IndexedByValue`] = {}
  state[dataName].forEach(v => {
    state[`${dataName}IndexedByValue`][v.value] = v
  })
}

const mutations = {
  setData(state, obj) {
    state.contactTypes = obj.contactTypes
    state.jobs = obj.jobs
    setDataIndexedById('jobs', state)
    state.noticeCategories = obj.noticeCategories
    setDataIndexedById('noticeCategories', state)
    state.masterLaboratoryCategories = obj.masterLaboratoryCategories
    setDataIndexedByCode('masterLaboratoryCategories', state)
    state.masterLaboratoryItemRanges = obj.masterLaboratoryItemRanges
    state.masterLaboratoryItemSpecies = obj.masterLaboratoryItemSpecies
    state.masterLaboratoryItemSpecimenTypes =
      obj.masterLaboratoryItemSpecimenTypes
    setDataIndexByValue('masterLaboratoryItemSpecimenTypes', state)
    state.masterLaboratoryItemUnits = obj.masterLaboratoryItemUnits
    setDataIndexedByCode('masterLaboratoryItemUnits', state)
    state.masterLaboratoryItems = obj.masterLaboratoryItems
    setDataIndexedByCode('masterLaboratoryItems', state)
    state.masterLaboratoryMachines = obj.masterLaboratoryMachines
    setDataIndexedByCode('masterLaboratoryMachines', state)
    state.patientSexes = obj.patientSexes
    state.prefectures = obj.prefectures
    setDataIndexedById('prefectures', state)
    state.publicHolidays = obj.publicHolidays
    state.reservationStatuses = obj.reservationStatuses
    setDataIndexedById('reservationStatuses', state)
    state.shiftTypes = obj.shiftTypes
    setDataIndexedById('shiftTypes', state)
  },
  setKarteData(state, obj) {
    state.unitTypes = obj.unitTypes
    setDataIndexedById('unitTypes', state)
    state.vaccines = obj.vaccines
    setDataIndexedById('vaccines', state)
    state.vaccineSubNames = obj.vaccineSubNames
    setDataIndexedById('vaccineSubNames', state)
    state.schemaImageClasses = obj.schemaImageClasses
    setDataIndexedById('schemaImageClasses', state)
  },
  deleteData(state) {
    state.prefectures = []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
