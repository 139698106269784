//バリデーション 正規表現
// email RFCに準拠
// ※参考にしたEメールアドレスの正規表現
//  https://qiita.com/sakuro/items/1eaa307609ceaaf51123
export const VALID_EMAIL_EMPTY_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$|^$/

// tel
export const VALID_TEL_REGEX = /^([0-9]{10}|[0-9]{11})$/
export const VALID_TEL_EMPTY_REGEX = /^([0-9]{10}|[0-9]{11})$|^$/
export const VALID_TEL_HYPHEN_REGEX = /^([0-9]{10}|[0-9]{11}|[0-9]{3}-[0-9]{4}-[0-9]{4}|[0-9]{2}-[0-9]{4}-[0-9]{4}|[0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{4}-[0-9]{2}-[0-9]{4}|[0-9]{5}-[0-9]-[0-9]{4})$|^$/

// postalCode
export const VALID_POSTALCODE_REGEX = /^\d{7}$/
export const VALID_POSTALCODE_EMPTY_REGEX = /^\d{7}$|^$/
export const VALID_POSTALCODE_HYPHEN_REGEX = /^\d{3}-\d{4}$/

//staffデータのpassword 半角英数字記号
//https://www-creators.com/archives/5187
//https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin_script
export const VALID_PASSWORD_REGEX = /^[!-~]+$/

// kana カタカナのみ
export const VALID_KATAKANA_REGEX = /^[ァ-ンヴー]+$/
export const VALID_KATAKANA_EMPTY_REGEX = /^[ァ-ンヴー]+$|^$/

// showId 半角数字とハイフンのみ
export const VALID_SHOW_ID_REGEX = /^[0-9-]+$/
export const VALID_SHOW_ID_EMPTY_REGEX = /^[0-9-]+$|^$/

// date(YYYYMMDD)
export const VALID_DATE_REGEX = /^(?!([02468][1235679]|[13579][01345789])000229)(([0-9]{4}(01|03|05|07|08|10|12)(0[1-9]|[12][0-9]|3[01]))|([0-9]{4}(04|06|09|11)(0[1-9]|[12][0-9]|30))|([0-9]{4}02(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}([02468][048]|[13579][26])0229))$/
export const VALID_DATE_EMPTY_REGEX = /^(?!([02468][1235679]|[13579][01345789])000229)(([0-9]{4}(01|03|05|07|08|10|12)(0[1-9]|[12][0-9]|3[01]))|([0-9]{4}(04|06|09|11)(0[1-9]|[12][0-9]|30))|([0-9]{4}02(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}([02468][048]|[13579][26])0229))$|^$/

// date(YYYY/MM/DD) 例 2022/06/01, 2022/12/30
export const VALID_DATE_SLASH_ZERO_REGEX = /^(?!([02468][1235679]|[13579][01345789])00\/02\/29)(([0-9]{4}\/(01|03|05|07|08|10|12)\/(0[1-9]|[12][0-9]|3[01]))|([0-9]{4}\/(04|06|09|11)\/(0[1-9]|[12][0-9]|30))|([0-9]{4}\/02\/(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}([02468][048]|[13579][26])\/02\/29))$/
// date(YYYY/MM/DD) 月と日の始めの0は入れない 例 2022/6/1, 2022/12/30
export const VALID_DATE_SLASH_NO_ZERO_REGEX = /^(?!([02468][1235679]|[13579][01345789])00\/2\/29)(([0-9]{4}\/(1|3|5|7|8|10|12)\/([1-9]|[12][0-9]|3[01]))|([0-9]{4}\/(4|6|9|11)\/([1-9]|[12][0-9]|30))|([0-9]{4}\/2\/([1-9]|1[0-9]|2[0-8]))|([0-9]{2}([02468][048]|[13579][26])\/2\/29))$/

// date(YYYYMM) 例 202206
export const VALID_YEAR_MONTH_REGEX = /^[0-9]{4}(0[1-9]|1[0-2])$/

// date(YYYY/MM) 例 2022/06, 2022/6
export const VALID_YEAR_MONTH_SLASH_REGEX = /^[0-9]{4}\/(0?[1-9]|1[0-2])$/

// date(YYYY)
export const VALID_YEAR_REGEX = /^[0-9]{4}$/

// date(YYYY/M/D) うるう年や存在しない日付かどうかまではチェックしていない簡易的な正規表現
export const VALID_DATE_SLASH_SIMPLE_YYYYMD_REGEX = /^[0-9]{4}\/[0-9]{1}\/[0-9]{1}$/
// date(YYYY/MM/D)
export const VALID_DATE_SLASH_SIMPLE_YYYYMMD_REGEX = /^[0-9]{4}\/[0-9]{2}\/[0-9]{1}$/
// date(YYYY/M/DD)
export const VALID_DATE_SLASH_SIMPLE_YYYYMDD_REGEX = /^[0-9]{4}\/[0-9]{1}\/[0-9]{2}$/

// color
export const VALID_COLOR_CODE_REGEX = /^#[a-fA-F0-9]{6}$/

// アニコムレセプターのカルテ番号
export const VALID_MEDICAL_RECORD_NUMBER_REGEX = /^[0-9]{1,20}[-][0-9]{1,20}$/

// 非サロゲートペア
export const VALID_SURROGATE_PAIR_REGEX = /[\uD800-\uDBFF][\uDC00-\uDFFF]/

// 苗字15+全角スペース+名前15
// export const VALID_FULL_NAME_REGEX = /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcfa-zA-Zａ-ｚＡ-Ｚ0-9０-９]{1,15}[\u3000]{1,1}[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcfa-zA-Zａ-ｚＡ-Ｚ0-9０-９]{1,15}$/
export const VALID_FULL_NAME_REGEX = /^[^"#$%&()*+\-\\/:;<=>@[\\\]^_`{|}~!?',.\u3000]{1,15}[\u3000]{1,1}[^"#$%&()*+\-\\/:;<=>@[\\\]^_`{|}~!?',.\u3000]{1,15}$/

// アニコムレセプターのカルテ番号上4桁0000は無効
export const VALID_ZERO_REGEX = /^(0{4}-)/

// ミョウジ15+全角スペース+ナマエ15
export const VALID_FULL_NAME_KANA_REGEX = /^[ァ-ンヴー]{1,15}[\u3000]{1,1}[ァ-ンヴー]{1,15}$/

export const POSTAL_CODE_API_URL =
  'https://madefor.github.io/postal-code-api/api/v1/'

export const SESSION_STORAGE_KEY = 'nu$98TA:@2fa)23mbQ'

export const HELP_LINK = 'https://support.plus-petorelu.jp/portal/ja/kb/yoyaku'
export const HELP_LINK_KARTE =
  'https://support.plus-petorelu.jp/portal/ja/kb/karute'

// メイン画面/週間のタイムテーブルボディのヘッダー
// 日付の部分 TimeTableWeekOneDay.vue class="day"のheight 26px
export const WEEK_DATE_HEADER_HEIGHT = 26
// 予約列名の部分 ReservationColumnWeek.vue class="column-name"のheight 80px
export const WEEK_RESERVATION_COLUMN_HEADER_HEIGHT = 80

export const CONSUMPTION_TAX_RATE = 10

// アニコム 証券番号
export const VALID_ANICOM_C_ID_REGEX = /^[a-zA-Z]\d{8}[a-zA-Z0-9]$|^$/

// アニコム 医療機関コード
export const VALID_ANICOM_H_CD_REGEX = /^a\d{5}$|^$/

// アイペット 病院コード
// ※plus_clinic では 保険金請求マニュアル.pdf > p56 8-7 に準拠
export const VALID_VH_CODE_REGEX = /^H\d{5}$|^$/

// 数字列
export const VALID_NUMERIC_STRING_REGEX = length => {
  return new RegExp(`^(\\d{${length}})$|^$`)
}

// インボイス 登録番号
export const VALID_INVOICE_NUMBER_REGEX = /^T\d{13}$|^$/

// 診療内容内のuploadImagesの画像またはmedicalContentImagesの画像のコンポーネントタグの取得で使用
export const COMPONENT_TAG_IN_CONTENT_REGEX = /<vue-rich-text-editor-image uploadimageid="[0-9]+"><\/vue-rich-text-editor-image>|<vue-rich-text-editor-medical-content-image medicalcontentimageid="[0-9]+" uploadimageid="[0-9]+"><\/vue-rich-text-editor-medical-content-image>/

// uploadImages画像のタグかどうかの判別で使用
export const UPLOAD_IMAGE_TAG_REGEX = /vue-rich-text-editor-image/

// medicalContentImages画像のタグかどうかの判別で使用
export const MEDICAL_CONTENT_IMAGE_TAG_REGEX = /vue-rich-text-editor-medical-content-image/

// 属性値の取得
export const CONTENT_IMAGE_ID_ATTRIBUTE_REGEX = /medicalcontentimageid="[0-9]+"/

// big.js における値判定の正規表現
// node_modules > big.js
export const BIG_NUMERIC_REGEX = /^-?(\d+(\.\d*)?|\.\d+)(e[+-]?\d+)?$/i

// カルテ登録 カルテの履歴の各診療内容部分の横幅 正確には604px。余白を持たせるため少し小さくした
// 診療内容内の画像がこの横幅を超えた時に、縮小させるためにこの値を使用
export const HISTORY_MEDICAL_CONTENT_WIDTH = 595

// ：（全角のコロン）の数値文字参照（10進数）の値
//http://www.htmq.com/text/65000-65999.shtml
export const FULL_WIDTH_COLON = '&#65306;'

// 保険の種類
const INSURANCES = ['anicom', 'ipet', 'docomo'] //←外部ファイルで使用する場合はexportにする
export const INSURANCES_EMPTY = [''].concat(INSURANCES) //未加入の場合は空文字

// アイペット保険の商品名
export const IPET_PLANS = ['うちの子', 'うちの子キュート', 'うちの子プラス']

export const DOCOMO_PLANS = ['ドコモのペット保険トータルケア']

// アイペット保険の各商品で選択できる補償割合 ※この定数では補償割合の値を文字列で保管
export const STRING_RATES_BY_PLAN = {
  うちの子: ['70', '50', '30'],
  うちの子キュート: ['70', '50', '30'],
  うちの子プラス: ['100', '70', '50'],
  ドコモのペット保険トータルケア: ['70', '50']
}

export const PREFECTURES = [
  { id: 1, name: '北海道' },
  { id: 2, name: '青森県' },
  { id: 3, name: '岩手県' },
  { id: 4, name: '宮城県' },
  { id: 5, name: '秋田県' },
  { id: 6, name: '山形県' },
  { id: 7, name: '福島県' },
  { id: 8, name: '茨城県' },
  { id: 9, name: '栃木県' },
  { id: 10, name: '群馬県' },
  { id: 11, name: '埼玉県' },
  { id: 12, name: '千葉県' },
  { id: 13, name: '東京都' },
  { id: 14, name: '神奈川県' },
  { id: 15, name: '新潟県' },
  { id: 16, name: '富山県' },
  { id: 17, name: '石川県' },
  { id: 18, name: '福井県' },
  { id: 19, name: '山梨県' },
  { id: 20, name: '長野県' },
  { id: 21, name: '岐阜県' },
  { id: 22, name: '静岡県' },
  { id: 23, name: '愛知県' },
  { id: 24, name: '三重県' },
  { id: 25, name: '滋賀県' },
  { id: 26, name: '京都府' },
  { id: 27, name: '大阪府' },
  { id: 28, name: '兵庫県' },
  { id: 29, name: '奈良県' },
  { id: 30, name: '和歌山県' },
  { id: 31, name: '鳥取県' },
  { id: 32, name: '島根県' },
  { id: 33, name: '岡山県' },
  { id: 34, name: '広島県' },
  { id: 35, name: '山口県' },
  { id: 36, name: '徳島県' },
  { id: 37, name: '香川県' },
  { id: 38, name: '愛媛県' },
  { id: 39, name: '高知県' },
  { id: 40, name: '福岡県' },
  { id: 41, name: '佐賀県' },
  { id: 42, name: '長崎県' },
  { id: 43, name: '熊本県' },
  { id: 44, name: '大分県' },
  { id: 45, name: '宮崎県' },
  { id: 46, name: '鹿児島県' },
  { id: 47, name: '沖縄県' }
]

//.*+?^=!:${}()|[]\ の文字を文字列として正規表現の判定内で使いたい場合にエスケープする関数
//参考サイト
//https://developer.mozilla.org/ja/docs/Web/JavaScript/Guide/Regular_Expressions
//※サイトに書いてあるコードだと、/の記号の前にも\をつけているがエラーになるので下記のコードではつけていないです。
export const escapeRegExp = string => {
  return string.replace(/[.*+?^=!:${}()|[/\]\\]/g, '\\$&')
}

export const ANICOM_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://k.anicom-ah.com/pc.php'
    : 'https://receipt-test.anicom-sompo.co.jp/pc.php'

// 印刷用紙のサイズ（単位 mm）
export const PAPER_SIZES = {
  A4: { x: 210, y: 297 },
  A5: { x: 148, y: 210 },
  B5ISO: { x: 176, y: 250 },
  B5: { x: 182, y: 257 },
  PC: { x: 100, y: 148 }
}

export const CAN_OPEN_MEASUREMENT_RESULT_TAB_CLINIC_IDS = [
  7, //高橋の医院アカウント
  8, //田邉さん
  10, //濱田常務
  168, //石田さん
  174 //中島さん
]
