import { getAxiosObject } from '@/utils/library'
import {
  commonActions,
  commonGetters,
  commonMutations
} from '@/store/store_utils'
import { toStateName } from '@/utils/convert_string'

const dataName = 'medicalRecords'
const keys = ['patientId']

const state = {
  medicalRecords: [],
  dataIndexedById: {},
  dataIndexedByOriginalId: {},
  updatedAt: '20101010101010',
  originalIdSetInTab: null,
  acquiredPatientIds: []
}
keys.forEach(key => {
  state[toStateName(key)] = null
})

const getters = {
  ...commonGetters(
    dataName,
    ['getData', 'getDataById', 'getDataByOriginalId', 'getUpdatedAt'],
    {
      filterDel: true,
      getDataBy: keys,
      getDataByIncludeDel: keys
    }
  ),
  getOriginalIdSetInTab(state) {
    return state.originalIdSetInTab
  },
  getAcquiredPatientIds(state) {
    return state.acquiredPatientIds
  }
}

const actions = {
  ...commonActions(['setData', 'setUpdatedAt', 'updateAfter']),
  setOriginalIdSetInTab({ commit }, originalId) {
    commit('setOriginalIdSetInTab', originalId)
  },
  resetOriginalIdSetInTab({ commit }) {
    commit('resetOriginalIdSetInTab')
  },
  async search({ commit }, { searchText, startDate, endDate, page, order }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/medical-records', {
        searchText,
        startDate,
        endDate,
        page,
        order
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.medicalRecords)
        commit('medicalPayments/updateAfter', res.data.medicalPayments, {
          root: true
        })
        commit(
          'medicalTreatmentItems/updateAfter',
          res.data.medicalTreatmentItems,
          { root: true }
        )
        commit('payments/updateAfter', res.data.payments, { root: true })
        return {
          result: 'success',
          medicalRecords: res.data.medicalRecords,
          medicalPayments: res.data.medicalPayments,
          medicalTreatmentItems: res.data.medicalTreatmentItems,
          count: res.data.count
        }
      }
    } catch (ex) {
      return { result: 'failure' }
    }
  },
  async detailSearch({ commit }, { searchConditions, page, order }) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.post('/medical-records/detail', {
        searchConditions,
        page,
        order
      })
      if (res.status === 200) {
        commit('updateAfter', res.data.medicalRecords)
        commit('medicalPayments/updateAfter', res.data.medicalPayments, {
          root: true
        })
        commit(
          'medicalTreatmentItems/updateAfter',
          res.data.medicalTreatmentItems,
          { root: true }
        )
        commit('payments/updateAfter', res.data.payments, { root: true })
        return {
          result: 'success',
          medicalRecords: res.data.medicalRecords,
          medicalPayments: res.data.medicalPayments,
          medicalTreatmentItems: res.data.medicalTreatmentItems,
          count: res.data.count
        }
      }
    } catch {
      return { result: 'failure' }
    }
  },
  async getPatientAllData({ commit }, patientId) {
    try {
      const axiosObject = getAxiosObject()
      const res = await axiosObject.get('/medical-records/patient-all-data', {
        params: { patientId }
      })
      if (res.status === 200) {
        commit('insertPatientId', patientId)
        commit('patients/updateAfter', [res.data.patient], { root: true })
        commit('uploadImages/setKarteUploadImages', res.data.uploadImages, {
          root: true
        })
        commit(
          'medicalContentImages/setKarteMedicalContentImages',
          res.data.medicalContentImages,
          { root: true }
        )
        const stores = [
          'anicomCIdChecks',
          'anicomReports',
          'antibodies',
          'antibodyTypes',
          'estimateTreatmentItems',
          'estimates',
          'examinationResults',
          'hospitalizations',
          'ipetChecks',
          'measurementResultItems',
          'measurementResults',
          'medicalContentImageTexts',
          'medicalContentImages',
          'medicalContents',
          'medicalPayments',
          'medicalRecords',
          'medicalTreatmentItems',
          'patientVaccinePeriods',
          'patientVaccines',
          'payments',
          'uploadImages',
          'vaccineReminders'
        ]
        stores.forEach(store => {
          commit(`${store}/updateAfter`, res.data[store], { root: true })
        })
        return 'success'
      }
    } catch {
      return 'failure'
    }
  },
  insertPatientId({ commit }, patientId) {
    commit('insertPatientId', patientId)
  },
  resetAcquiredPatientIds({ commit }) {
    commit('resetAcquiredPatientIds')
  }
}

const mutations = {
  ...commonMutations(dataName, ['setUpdatedAt', 'setData', 'updateAfter'], {
    indexedById: true,
    indexedByOriginalId: true,
    setDataBy: keys
  }),
  setOriginalIdSetInTab(state, originalId) {
    state.originalIdSetInTab = originalId
  },
  resetOriginalIdSetInTab(state) {
    state.originalIdSetInTab = null
  },
  insertPatientId(state, patientId) {
    state.acquiredPatientIds.push(patientId)
  },
  resetAcquiredPatientIds(state) {
    state.acquiredPatientIds = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
