<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="examination-list-popup"
      tabindex="-1"
      @keyup.esc.stop="closePopup"
    >
      <popup
        :buttons="['閉じる']"
        @click-close-mark="closePopup"
        @close="closePopup"
      >
        <template v-slot:content>
          <div class="content">
            <div class="title">検査登録</div>
            <div class="search">
              <search-area
                v-model="searchText"
                :placeholder="placeholder"
                :searchAreaStyles="{ minWidth: '590px', width: '590px' }"
                :textBoxLabel="'検索単語'"
                :textValue="searchText"
              />
            </div>
            <div class="tabs" v-if="canOpenMeasurementResultTab">
              <tab-list
                :activeTab="activeTab"
                :tabs="tabs"
                @click="switchTab"
              />
            </div>
            <div class="list">
              <div v-show="activeTab === '検査入力'" class="examinations">
                <accordion-list
                  ref="accordionList"
                  :data="examinations"
                  :keyIndexFlg="!!searchText"
                  :styles="{ height: '300px' }"
                  @click-row="openExaminationPopup"
                />
              </div>
              <div
                v-show="activeTab === '検査結果'"
                class="measurement-results"
              >
                <data-table
                  v-bind="dataTableOptions"
                  :items="measurementResults"
                  @click-item="openMeasurementResultPopup"
                />
              </div>
            </div>
          </div>
        </template>
      </popup>
      <examination-popup
        v-if="showExaminationPopup"
        :examinationResultGroups="examinationResultGroups"
        :examinationResultGroupsIncludeDel="examinationResultGroupsIncludeDel"
        :format="format"
        :medicalRecordDate="medicalRecordDate"
        :patientId="patientId"
        :treatment="treatment"
        @close="closeExaminationPopup"
        @copy="copy"
      />
      <measurement-result-popup
        v-if="showMeasurementResultPopup"
        :layer-number="2"
        :measurement-result-id="selectedMeasurementResultId"
        @close="closeMeasurementResultPopup"
      />
      <!-- <announce-popup
        v-if="alertFlg"
        @cancel="alertFlg = false"
        @decision="goToExaminationRegistration"
        :title="'注意'"
        :buttons="['閉じる', '基準値設定']"
        :type="'alert'"
        >検査基準値が未登録となっています。検査基準値の登録を行いますか？</announce-popup
      > -->
    </div>
  </focus-trap>
</template>

<script>
import AccordionList from '@/components/parts/organisms/AccordionList'
// import AnnouncePopup from '@/components/popups/AnnouncePopup'
import DataTable from '@/components/parts/organisms/DataTable'
import ExaminationPopup from '@/components/popups/ExaminationPopup'
import MeasurementResultPopup from '@/components/popups/MeasurementResultPopup'
import Popup from '@/components/popups/Popup'
import SearchArea from '@/components/parts/molecules/SearchArea'
import TabList from '@/components/parts/molecules/TabList'
import moment from 'moment'
import { FocusTrap } from 'focus-trap-vue'
import { compareByKeys } from '@/utils/compare'
import { mapGetters } from 'vuex'
import { CAN_OPEN_MEASUREMENT_RESULT_TAB_CLINIC_IDS } from '@/utils/define'

export default {
  name: 'ExaminationListPopup',

  components: {
    AccordionList,
    // AnnouncePopup,
    DataTable,
    ExaminationPopup,
    FocusTrap,
    MeasurementResultPopup,
    Popup,
    SearchArea,
    TabList
  },

  props: {
    examinationResultGroups: { type: Object },
    examinationResultGroupsIncludeDel: { type: Object },
    medicalRecordDate: { type: String },
    patientId: { type: Number }
  },

  data() {
    const listHeight = 300
    const defaultCellStyle = { fontSize: '13px', textAlign: 'left' }
    const headerCellStyles = [
      { ...defaultCellStyle, paddingLeft: '8px', width: '210px' },
      { ...defaultCellStyle, paddingLeft: '8px', flex: 1 }
    ]
    const bodyCellStyles = [
      { ...defaultCellStyle, lineHeight: '40px', width: '210px' },
      { ...defaultCellStyle, lineHeight: '40px', flex: 1 }
    ]
    return {
      activeTab: '検査入力',
      alertFlg: false,
      examinationFormat: {},
      showExaminationPopup: false,
      format: {},
      dataTableOptions: {
        bodyCellStyles,
        bodyStyle: { maxHeight: listHeight - 30 + 'px', overflowY: 'auto' },
        clickable: true,
        headers: ['検査日時', '検査機器'],
        headerCellStyles,
        keys: ['jpDate', 'machineName'],
        stripeFlg: true
      },
      searchText: '',
      selectedMeasurementResultId: 0,
      showMeasurementResultPopup: false,
      tabs: ['検査入力', '検査結果'],
      treatment: {}
    }
  },

  computed: {
    ...mapGetters({
      clinic: 'clinic/getData',
      getMasterLaboratoryMachineIndexedByCode:
        'master/getMasterLaboratoryMachineIndexedByCode',
      measurementResultsByPatientId: 'measurementResults/getDataByPatientId',
      treatmentLargeClasses: 'treatmentLargeClasses/getData',
      treatmentClassesByLargeOriginalId:
        'treatmentClasses/getDataByTreatmentLargeClassOriginalId',
      formatsByTreatmentOriginalId:
        'examinationFormats/getDataByTreatmentOriginalId'
    }),
    productionMode() {
      return process.env.NODE_ENV === 'production'
    },
    canOpenMeasurementResultTab() {
      return (
        !this.productionMode ||
        (this.productionMode &&
          CAN_OPEN_MEASUREMENT_RESULT_TAB_CLINIC_IDS.includes(this.clinic.id))
      )
    },
    examinations() {
      return this.searchText
        ? this.filteredTreatmentClasses.concat(this.filteredTreatments)
        : this.filteredTreatmentClasses
    },
    filteredTreatmentClasses() {
      return this.treatmentClasses
        .filter(v => this.filterBySearchText(v.name))
        .map(treatmentClass => {
          const treatments = this.getTreatmentsByClassOriginalId(
            treatmentClass.originalId
          )
          const children = treatments.map(treatment => {
            return {
              ...treatment,
              label: this.makeExaminationStatus(treatment.originalId),
              layerNumber: 2
            }
          })
          return {
            ...treatmentClass,
            name: this.toTreatmentClassName(treatmentClass.name),
            children,
            layerNumber: 1
          }
        })
    },
    filteredTreatments() {
      return this.treatments
        .filter(v => this.filterBySearchText(v.name))
        .map(treatment => {
          return {
            ...treatment,
            label: this.makeExaminationStatus(treatment.originalId),
            layerNumber: 1
          }
        })
    },
    measurementResults() {
      const patientMeasurementResults =
        this.measurementResultsByPatientId(this.patientId) ?? []
      return patientMeasurementResults
        .map(v => ({
          ...v,
          jpDate: this.toJpDatetime(v.date, v.time),
          machineName: this.toMachineName(v.masterLaboratoryMachineCode)
        }))
        .filter(v => this.filterBySearchText(v.machineName))
        .sort((a, b) => compareByKeys(a, b, ['date', 'time', 'id']))
    },
    placeholder() {
      if (this.activeTab === '検査入力') {
        return '診療項目分類名、診療項目名'
      } else if (this.activeTab === '検査結果') {
        return '検査機器'
      }
      return ''
    },
    treatmentClasses() {
      const examinationTreatmentLargeClass = this.treatmentLargeClasses.find(
        t => t.examinationFlg
      )
      const examinationTreatmentClasses =
        this.treatmentClassesByLargeOriginalId(
          examinationTreatmentLargeClass.originalId
        ) ?? []
      return examinationTreatmentClasses.filter(v => v.showFlg)
    },
    treatments() {
      return this.treatmentClasses.reduce(
        (treatments, treatmentClass) =>
          treatments.concat(
            this.getTreatmentsByClassOriginalId(treatmentClass.originalId)
          ),
        []
      )
    }
  },

  methods: {
    copy(examinationResultGroup) {
      this.$emit('copy', examinationResultGroup)
      this.showExaminationPopup = false
    },
    closeExaminationPopup() {
      this.showExaminationPopup = false
      this.treatment = {}
      this.format = {}
    },
    closeMeasurementResultPopup() {
      this.showMeasurementResultPopup = false
      this.selectedMeasurementResultId = 0
    },
    closePopup() {
      this.$emit('close')
    },
    filterBySearchText(text) {
      return text.toLowerCase().includes(this.searchText.toLowerCase().trim())
    },
    getTreatmentsByClassOriginalId(treatmentClassOriginalId) {
      const treatments =
        this.$store.getters['treatments/getDataByTreatmentClassOriginalId'](
          treatmentClassOriginalId
        ) ?? []
      return treatments.filter(v => v.showFlg)
    },
    makeExaminationStatus(treatmentOriginalId) {
      const examinationFormats = this.formatsByTreatmentOriginalId(
        treatmentOriginalId
      )
      return examinationFormats ? '' : '検査基準値未登録'
    },
    openExaminationPopup(treatment) {
      const examinationFormats = this.formatsByTreatmentOriginalId(
        treatment.originalId
      )
      this.treatment = treatment
      if (examinationFormats?.length > 0) {
        this.format = examinationFormats[0]
        this.showExaminationPopup = true
        // } else {
        //   this.alertFlg = true
      }
    },
    openMeasurementResultPopup(measurementResult) {
      this.selectedMeasurementResultId = measurementResult.id
      this.showMeasurementResultPopup = true
    },
    switchTab(selectedTab) {
      this.activeTab = selectedTab
      this.searchText = ''
      this.$refs.accordionList.closePanel()
    },
    toJpDatetime(date, time) {
      if (time === '') {
        return moment(date, 'YYYYMMDD').format(`Y年M月D日（dd）`)
      } else {
        const datetime = date + time
        return moment(datetime, 'YYYYMMDDHHmm').format(`Y年M月D日（dd）H時m分`)
      }
    },
    toMachineName(masterLaboratoryMachineCode) {
      return this.getMasterLaboratoryMachineIndexedByCode(
        masterLaboratoryMachineCode
      ).name
    },
    toTreatmentClassName(name) {
      return this.searchText ? '分類 / ' + name : name
    }
    // goToExaminationRegistration() {
    //   this.$router.push({
    //     name: 'examination-new',
    //     params: { originalId: this.treatment.originalId }
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.examination-list-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    box-sizing: border-box;
    > .title {
      font-size: 16px;
      font-weight: bold;
      text-align: left;
    }
    > .search {
      margin-top: 15px;
    }
    > .tabs {
      margin-top: 25px;
    }
    > .list {
      margin-top: 25px;
      > .measurement-results {
        height: 300px;
        width: 590px;
      }
    }
  }
}
</style>
