<template>
  <div class="tab-list">
    <div
      v-for="tab in tabs"
      class="tab"
      :class="{ active: tab === activeTab }"
      :key="tab"
      @click="clickTab(tab)"
    >
      <div>{{ tab }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabList',

  props: {
    activeTab: { type: String, default: '' },
    tabs: { type: Array, default: () => [] }
  },

  methods: {
    clickTab(tab) {
      this.$emit('click', tab)
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-list {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
  border-bottom: 1px solid;
  border-color: #{$light-grey};
  > .tab {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    cursor: pointer;
    padding-bottom: 6px;
    &.active {
      padding-bottom: 2px;
      border-bottom: 4px solid;
      border-color: #{$pumpkin};
      color: #{$pumpkin};
    }
  }
}
</style>
