var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('node-view-wrapper',{staticClass:"rich-text-editor-examination-table",style:(_vm.hoverStyles)},[_c('div',{staticClass:"examination-result-group-table-area",on:{"click":_vm.handleClick}},[_c('table',[_c('tr',[_c('th',{staticClass:"w30"},[_vm._v("登録日")]),_c('td',{staticClass:"w70",attrs:{"data-test":"date"}},[_vm._v(" "+_vm._s(_vm.displayExaminationResults[0] ? _vm.displayExaminationResults[0].date : '')+" ")])]),_c('tr',[_c('th',{staticClass:"w30",domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.escapeTextExceptHighLight('検査名', true)))}}),_c('td',{staticClass:"w70",attrs:{"data-test":"treatmentName"},domProps:{"innerHTML":_vm._s(
            _vm.$sanitize(
              _vm.escapeTextExceptHighLight(
                _vm.displayExaminationResults[0]
                  ? _vm.displayExaminationResults[0].treatmentName
                  : ''
              )
            )
          )}})]),_c('tr',[_c('th',{staticClass:"w30",domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.escapeTextExceptHighLight('表示種別', true)))}}),_c('td',{staticClass:"w70",attrs:{"data-test":"speciesName"},domProps:{"innerHTML":_vm._s(
            _vm.$sanitize(
              _vm.escapeTextExceptHighLight(
                _vm.displayExaminationResults[0]
                  ? _vm.displayExaminationResults[0].speciesName
                  : ''
              )
            )
          )}})])]),_c('table',[_c('tr',[_c('th',{staticClass:"w15",domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.escapeTextExceptHighLight('項目区分名', true)))}}),_c('th',{staticClass:"w15",domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.escapeTextExceptHighLight('項目名', true)))}}),_c('th',{staticClass:"w11_25",domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.escapeTextExceptHighLight('結果', true)))}}),_c('th',{staticClass:"w7_5",domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.escapeTextExceptHighLight('単位', true)))}}),_c('th',{staticClass:"w11_25"},[_vm._v("基準値")]),_c('th',{staticClass:"w40",domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.escapeTextExceptHighLight('コメント', true)))}})]),_vm._l((_vm.makeFilteredResultGroupData(
          Object.values(_vm.resultsGroupedByTopicClassId)
        )),function(results){return _vm._l((results),function(result,n){return _c('tr',{key:results[0].examinationTopicClassId + '_' + n},[(n === 0)?_c('td',{staticClass:"w15",attrs:{"rowspan":results.length,"data-test":"topicClassName"},domProps:{"innerHTML":_vm._s(
              _vm.$sanitize(_vm.escapeTextExceptHighLight(result.topicClassName))
            )}}):_vm._e(),_c('td',{staticClass:"w15",attrs:{"data-test":"topicName"},domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.escapeTextExceptHighLight(result.topicName)))}}),_c('td',{staticClass:"w11_25",attrs:{"data-test":"result"},domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.escapeTextExceptHighLight(result.result)))}}),_c('td',{staticClass:"w7_5",attrs:{"data-test":"unit"},domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.escapeTextExceptHighLight(result.unit)))}}),_c('td',{staticClass:"w11_25",attrs:{"data-test":"reference"}},[_vm._v(_vm._s(result.reference))]),_c('td',{staticClass:"w40",attrs:{"data-test":"comment"},domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.escapeTextExceptHighLight(result.comment)))}})])})})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }